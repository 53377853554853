import React from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Privacy() {
    return (
        <div>
            <Navigation2 />
            {
                window.scrollTo(0, 0)
            }
            <Container style={{ marginTop: '150px' }}>
                <Row>
                    <Col>
                        <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Privacy Policy</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            Objective, Scope and Applicability of the Policy, The privacy policy (“Policy”) is applicable to the online interfaces of RoomsNearYou. (“RoomsNearYou”, “us”, “we” or “our”) including <Link to="/">www.roomsnearyou.in</Link> , the mobile site/applications (including but not limited to the RoomsNearYou, or any other app) for iOS, Android, or any other windows operating system (collectively referred to as “Site”). The purpose of this Policy is to describe how RoomsNearYou collects, uses, maintains and shares information about you (“user” or “you” or “your”) on account of you accessing and using the Site. This information may include but is not limited to any information you upload, emails that you exchange with RoomsNearYou and other users of the Site and any information submitted/ provided by you to RoomsNearYou.
                            <br />
                            <br />
                            We recognize the importance of your privacy and we are committed to ensuring that you are aware of how your information is being used. Please read this Policy carefully to understand how we collect and use your information. Your use of the Site is also governed by our Terms of Use. You are free to use the Site only if you agree with our policies and practices encapsulated in this Policy read in conjunction with the Terms of Use.
                            <br />
                            <br />
                            By using the Site and/or by providing your information to us, you consent to the collection and use of the information you disclose on the Site in accordance with this Policy. If you wish to contact RoomsNearYou in connection with any matter relating to this Policy or privacy related issues you may send an email to <strong>support@roomsnearyou.in</strong> .
                            <br />
                            <br />
                            By browsing, visiting, accessing and/or using the services on the Site, you consent and agree to the terms of RoomsNearYou's Policy as detailed herein.
                            <br />
                            <br />
                            You also agree that the information furnished by you is lawful, true and correct and does not violate or infringe any laws. In case of any violations, infringement, furnishing of wrongful or unauthorized information, RoomsNearYou shall not be liable to you or to any third party for the same.
                            <br />
                            <br />
                            We use data collection devices such as "cookies" on certain pages of the Site to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. The term "cookies" means small files placed on your hard drive that assist us in providing our services. We also use cookies to allow you to enter your contact less frequently during a session. Cookies can also help us provide information that is targeted to your interests. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Site. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Site that are placed by third parties. RoomsNearYou does not control the use of cookies by third parties.
                            <br />
                            <br />
                            Types/ Nature of Information we Collect: There are three (3) general categories of information we collect.
                            <br />
                            <br />
                            A. Information you provide us, to enable your use of the Account/Site
                            <br />
                            B. Information you choose to give us
                            <br />
                            C. Information that is necessary for the payment related services
                            <br />
                            <br />
                            Further to each of the aforementioned categories set out in Section II: Sub-Section A, B and C above we collect the following types of information from you (collectively referred to as “Information”) “Personally Identifiable Information” - information that could be used to identify you such as, your name, email address, age, gender, IP addresses and other similar information; “Sensitive Personal Information” such as contact in encrypted form and secured financial/ payment related information; “Non-Personal Information” - information that cannot be used to identify you such as the webpages that you have viewed and your usage patterns; and (iv) “Device Information”- information which is automatically collected about the device from which the Site is used.
                            <br />
                            <br />
                            Information you provide RoomsNearYou to enable your use of the Account/Site We ask for and collect the following Personally Contact Number about you when you use the Site. This information is necessary for performance of the agreement between you and us and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested services.
                            <br/>
                            <br/>
                            Account Information. In order to use certain features on the Site, RoomsNearYou may require you to create a user account (“Account”) which shall include your user profile (“Profile”). When you opt to create such an Account and login /click on “I Accept” in respect of the creation of an Account, we require you to provide certain Personally Identifiable information such as your first name, last name, email address, date of birth, phone number, and depending on the nature of services you avail.
                            <br/>
                            <br/>
                            Profile and Listing Information. In addition to (i) above, in order to use certain features on the Site (such as requesting or creating a listing), we may ask you to provide certain Personally Contact Number.
                            <br/>
                            <br/>
                            To help create and maintain a trusted environment, we may collect certain contact and Name in order to verify your identity.
                            <br/>
                            <br/>
                            Payment Information. In addition to the foregoing, in order to use certain other features on the Site (such as booking or creating a listing), we may require you to provide certain financial information (as more particularly described in sub section C below) in order to facilitate the processing of payments.
                            <br/>
                            <br/>
                            Communications with RoomsNearYou and its affiliates and authorised service providers or other users. When you communicate with RoomsNearYou or use the Site to communicate with RoomsNearYou’s affiliates and authorised service providers or when you communicate with other users of RoomsNearYou, we collect all such correspondence, information about your communication and any additional information you choose to provide.
                            <br/>
                            <br/>
                            Information you choose to give us, You may choose to provide us with additional Personally contact number in order to obtain a better user experience when using RoomsNearYou.
                            <br/>
                            <br/>
                            Payment Information. When you use any payment services on the Site, certain financial information may be required to facilitate the processing of such payments. You are to note that RoomsNearYou engages with third party payment service providers in order to enable you to process your payments in connection with the use of RoomsNearYou’s services. You will be required by such third-party payment service providers to provide certain Sensitive Personal Information. Please note that to this extent, you will be subject to the terms of use and privacy policy that is maintained by such third-party service provider.
                            <br/>
                            <br/>
                            By accepting this policy you agree to receive communications via WhatsApp.
                            <br/>
                            <br/>
                            DATA SECURITY The Information that you provide, subject to disclosure in accordance with this Policy and Terms of Use, shall be maintained in a safe and secure manner. RoomsNearYou's databases and Information are stored on secure servers with appropriate firewalls owned by RoomsNearYou or by third parties.
                            <br/>
                            <br/>
                            You acknowledge that as a registered user you are responsible for maintaining the security of your Account such as your Account login information, and that you should not provide these credentials to any third party. If it comes to your knowledge that or if you have reason to believe that your Account credentials have been stolen or been made known to others, you must contact us immediately at <strong>support@roomsnearyou.in</strong> . You acknowledge that you are responsible for all acts done, using your Account login credentials. RoomsNearYou is not responsible if someone else accesses your Account on account of your failure to maintain security of your Account credentials.
                            <br/>
                            <br/>
                            Data protection contacts: If you have any questions, comments, complaints or suggestions in relation to this Policy, or any other concerns about the way in which we process information about you, please contact our Grievance Redressal Officer (detail mentioned below) at <strong>support@roomsnearyou.in</strong> . We shall endeavour to address all requests/complaints within twenty four (24) hours from the date of such a request/complaint being made.
                            <br/>
                            <br/>
                            Grievance Redressal Officer email: support@roomsnearyou.in
                            <br/>
                            <br/>
                            <h1 style={{ fontSize: '13px', fontFamily: 'DM Sans', color:'#95a5a6'}}>This Policy has been last updated on 24th nov, 2021.</h1>
                        </h1>

                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
