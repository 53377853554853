import React from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col, Accordion } from 'react-bootstrap'

export default function TenantFaq() {
    return (
        <div>
            <Navigation2 />
            <Container style={{ marginTop: '150px' }}>
                <Row>
                    <Col>
                        <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Tenant's Frequently Asked Questions (FAQ)</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>Is this free?</Accordion.Header>
                                <Accordion.Body>
                                    <li style={{ fontFamily: 'DM Sans' }}>Yes its totally free for tenants and landords both (no commission and no brokerage charge).</li>
                                    <li> हाँ यह किरायेदारों और जमींदारों दोनों के लिए पूरी तरह से मुफ़्त है (कोई कमीशन नहीं और कोई ब्रोकरेज शुल्क नहीं)।</li>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>How can I send room request to landlord?</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li><strong>English</strong></li>
                                        <ul>
                                            <li>Find rooms as your interest or convenient</li>
                                            <li>Login as a tenent</li>
                                            <li>Click on contact landlord button to send request to landlord</li>
                                        </ul>

                                        <li><strong>Hindi</strong></li>
                                        <ul>
                                            <li>अपनी रुचि या सुविधा के अनुसार कमरे खोजें|</li>
                                            <li>एक किरायेदार के रूप में लॉगिन करें|</li>
                                            <li>मकान मालिक को अनुरोध भेजने के लिए contact landlord बटन पर क्लिक करें|</li>
                                        </ul>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
