import React, { useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import '../componentCss/Pricing.css'
import pricing from '../pricing.png'
import pricing2 from '../pricing2.png'
import { FaCheckDouble } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

export default function Pricing() {
    const [btnClick, setBtnClick] = useState('landlord')

    return (
        <div id="priceID">
            <Container className="pricing-container" fluid>
                <Container style={{ marginTop: '30px' }}>
                    <Row>
                        <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                            <span id="pricing-plan-text">Pricing Plan</span><br />
                            <span id="pricing-plan-text2">Which deal suit you perfect ?</span>
                        </Col>
                    </Row>
                </Container>

                <div className="pricing-btn-container">
                    <Row>
                        <Col>
                            <Button id={btnClick === 'landlord' ? 'pricing-btn-active' : 'pricing-btn-deactive'} onClick={() => setBtnClick('landlord')}>Landlord</Button>
                            <Button id={btnClick === 'tenant' ? 'pricing-btn-active' : 'pricing-btn-deactive'} onClick={() => setBtnClick('tenant')}>Tenant</Button>
                        </Col>
                    </Row>
                </div>
                <Container>
                    <Row>

                        {
                            btnClick === 'landlord' ? <Landlord /> : <Tenant />
                        }

                    </Row>
                </Container>
                <br />
                <br />
            </Container>

            <Container>

            </Container>
        </div>
    )
}


function Landlord() {
    return (
        <>
            <Col>
                <Card className="pricing-landlord-card-white">
                    <Fade><Card.Img variant="top" src={pricing2} id="pricing-landlord-card-white-img" /></Fade>
                    <Card.Body>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans' }}>Free Plan</Card.Title></Fade>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}>Free</Card.Title></Fade>
                        <Slide bottom><Card.Text>
                            <br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 30 Days Validity</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> ID Verified Tenants</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Unlimited Bookings</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 24*7 Customer Support</span><br /><br />
                            {/* <Button className="buyPlanBtn mx-auto d-block" style={{ marginTop: '350px' }} onClick={() => buyPlann()}>Buy Plan</Button> */}
                        </Card.Text></Slide>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className="pricing-landlord-card-black">
                    <Fade><Card.Img variant="top" src={pricing} id="pricing-landlord-card-black-img" /></Fade>
                    <Card.Body>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans' }}>Standard Plan</Card.Title></Fade>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}><strike>₹799/-</strike> ₹599/- </Card.Title></Fade>
                        <Slide bottom><Card.Text>
                            <br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 90 Days Validity</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> ID Verified Tenants</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Unlimited Bookings</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Expert Photoshoot</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Property Tag</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 5 Rooms Listing</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Digitally Promoted</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 24*7 Customer Support</span><br /><br />
                            {/* <Button className="buyPlanBtn mx-auto d-block" style={{ marginTop: '150px' }}>Buy Plan</Button> */}
                        </Card.Text></Slide>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className="pricing-landlord-card-white">
                    <Fade><Card.Img variant="top" src={pricing2} id="pricing-landlord-card-white-img" /></Fade>
                    <Card.Body>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans' }}>Premium Plan</Card.Title></Fade>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}><strike>₹1499/-</strike> ₹999/-</Card.Title></Fade>
                        <Slide bottom><Card.Text>
                            <br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 180 Days Validity</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> ID Verified Tenants</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Unlimited Bookings</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Expert Photoshoot</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Expert Video Shoot</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Property Tag</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Owner Tag</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Unlimited Rooms Listing</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Featured Listing</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Digitally Promoted</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 24*7 Customer Support</span><br /><br />
                            {/* <Button className="buyPlanBtn mx-auto d-block" style={{ marginTop: '10px' }}>Buy Plan</Button> */}
                        </Card.Text></Slide>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}
function Tenant() {
    return (
        <>
            {/* <Col>
                <Card className="pricing-landlord-card-black">
                    <Fade><Card.Img variant="top" src={pricing} id="pricing-landlord-card-black-img" /></Fade>
                    <Card.Body>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans' }}>Starter Pack</Card.Title></Fade>
                        <Slide bottom><Card.Text>
                            <br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Free Searching</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Book room only in 30<FaRupeeSign style={{ fontSize: '12' }} /></span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Landlords</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Rooms</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Privacy of your personal details</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 24*7 Customer Support</span><br /><br />
                        </Card.Text></Slide>
                    </Card.Body>
                </Card>
            </Col> */}
            <Col md={4} className="mx-auto d-block">
                <Card className="pricing-landlord-card-white">
                    <Fade><Card.Img variant="top" src={pricing2} id="pricing-landlord-card-white-img" /></Fade>
                    <Card.Body>
                        <Fade><Card.Title style={{ fontFamily: 'DM Sans' }}>Pay Per Booking</Card.Title></Fade>
                        <Slide bottom><Card.Text>
                            <br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Free Searching</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Unlimited Bookings</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Landlords</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Verified Rooms</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Privacy of your personal details</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> Provide Personal Assistant</span><br /><br />
                            <span><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /> 24*7 Customer Support</span><br /><br />
                            {/* <Button className="buyPlanBtn mx-auto d-block">Buy Plan</Button> */}
                        </Card.Text></Slide>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}
