import './App.css';
import { Switch, Route, Link } from "react-router-dom";
import Home from './components/Home';
import Rooms from './components/Rooms'
import LandlordDashboard from './components/LandlordProfile/LandlordDashboard';
import UploadRooms from './components/LandlordProfile/UploadRooms';
import TenantDashboard from './components/TenantProfile/TenantDashboard';
import UploadRoom from './components/LandlordProfile/UploadRooms';
import Test from './components/LandlordProfile/Test';
import LandLordLogout from './components/LandlordProfile/LandlordLogout';
import TenantLogout from './components/TenantProfile/TenantLogout';
import Career from './components/Career';
import TenantFaq from './components/TenantFaq';
import Terms from './components/Terms';
import LandlordFaq from './components/LandlordFaq';
import Privacy from './components/Privacy';
import Contact from './components/Contact';
import Refund from './components/Refund';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";


const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  uri: 'https://roomsnearyou-backend.vercel.app/',
  onError: (e) => { console.log("ERRRRRRR", e) },
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});


function App() {
  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path="/" exact={true}><Home /></Route>
        <Route path="/rooms"><Rooms /></Route>
        <Route path="/landlordDashboard"><LandlordDashboard /></Route>
        <Route path="/roomUpload"><UploadRooms /></Route>
        <Route path="/tenantDashboard"><TenantDashboard /></Route>
        <Route path="/roomUpload"><UploadRoom /></Route>
        <Route path="/test"><Test /></Route>
        <Route path="/test"><Test /></Route>
        <Route path="/landlordLogout"><LandLordLogout /></Route>
        <Route path="/tenantLogout"><TenantLogout /></Route>
        <Route path="/career"><Career /></Route>
        <Route path="/tenantFaq"><TenantFaq /></Route>
        <Route path="/landlordFaq"><LandlordFaq /></Route>
        <Route path="/terms"><Terms /></Route>
        <Route path="/privacy"><Privacy /></Route>
        <Route path="/contact"><Contact /></Route>
        <Route path="/refund"><Refund /></Route>
        <Route path="*"><PageNotFound /></Route>
      </Switch>
    </ApolloProvider>
  );
}


function PageNotFound() {
  return (
    <div>
      <h1>It Is 404 Page Not Found</h1>
      <center><h5 id="returnToHome">Return to <Link to="/">Home</Link></h5></center>
    </div>
  )
}

//https://roomsapollo.herokuapp.com/graphql
//http://localhost:4000/graphql

export default App;
