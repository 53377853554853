import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import tenant from '../verifiedTenant.png';
import property from '../verifiedProperty.png';
import support from '../support.png';
import AboutPic from '../aboutPic.jpg'

export default function About() {
    return (
        <div id="aboutID">
            <Container style={{marginTop:'30px'}}>
                <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2 style={{fontFamily:'DM Sans'}}>About Us</h2>
                    </Col>
                </Row>
            </Container>
            <Container style={{marginTop:'30px'}}>
                <Row>
                    <Col md={6}>
                        <h2 style={{lineHeight: '1.5', color:'#343D48', fontFamily:'DM Sans'}}>
                            List your property in RoomsNearYou and reach thousands of peoples who need the rooms.
                        </h2>
                        <br/>
                        <span style={{lineHeight: '2.07', color:'#343D48', fontFamily:'DM Sans'}}>
                            RoomsNearYou is the online rooms, apartments, flats, etc rental aggregation website for peoples in need of independent and comfortable living spaces in your city by the partnering with property owners and landlords we provide the verified listing to you. Our website mostly focuses on the students, employees and families also, who are seeking for comfortable rooms. So find spaces as per your convenience and enjoy your stay.
                        </span>
                        <br/>
                        
                        <Row>
                            <Col>
                                <Image src={tenant} style={{ width: '50px', height: '50px' }} />
                                <h2 style={{ fontSize: '15px', fontFamily: "DM Sans", fontWeight: 'bold' }}>Verified Tenants</h2>
                            </Col>
                            <Col>
                                <Image src={property} style={{ width: '50px', height: '50px' }} />
                                <h2 style={{ fontSize: '15px', fontFamily: "DM Sans", fontWeight: 'bold' }}>Verified Properties</h2>
                            </Col>
                            <Col>
                                <Image src={support} style={{ width: '50px', height: '50px' }} />
                                <h2 style={{ fontSize: '15px', fontFamily: "DM Sans", fontWeight: 'bold' }}>27x7 Support</h2>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className="d-none d-lg-block">
                        <Image src={AboutPic} style={{ height: '510px', width: '100%', borderRadius:'10px'}} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
