import React from 'react'
import { Row, Col, Card, Carousel, Image, Container } from 'react-bootstrap';
import './LandlordCss/ExpiredRoomsCss.css'
import ExpiredImg from '../../expired.png'
import { useQuery } from '@apollo/client'
import { EXPIRED_ROOMS } from '../../Queries'
import Spinner1 from './Spinner1'
import { FaExclamationTriangle, FaRegFolderOpen } from "react-icons/fa";


export default function ExpiredRooms() {

    const landlordId = localStorage.getItem('LandlardId');

    const { data: expiredRoomsData, loading: expiredRoomsLoading, error: expiredRoomsError } = useQuery(EXPIRED_ROOMS, {
        variables: {
            getExpiredRoomsLandlordId: `${landlordId}`
        }
    });
    if (expiredRoomsError) {
        console.log("expiredRoomsError", expiredRoomsError)
    }
    if (expiredRoomsData) {
        console.log("expiredRooms", expiredRoomsData)
    }

    return (
        <div>
            {
                expiredRoomsData && expiredRoomsData.getExpiredRooms.length === 0 ?
                    <Container>
                        <Row>
                            <Col>
                                <FaRegFolderOpen style={{ fontSize: '100px' }} className="mx-auto d-block" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5 style={{ color: '#e74c3c', textAlign: 'center', fontFamily: 'DM Sans', marginTop: '10px', fontSize: '30px' }}>No Expired Rooms Found!!!</h5>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <h5 style={{ color: '#e74c3c', textAlign: 'center', marginBottom: '15px', fontFamily: 'DM Sans', fontSize: '16px' }}><FaExclamationTriangle style={{ color: 'red' }} /> Upgrade your plan for activate these rooms</h5>
            }

            <Card className="expiredCard">
                <Card.Body>
                    {expiredRoomsLoading ? <Spinner1 /> :

                        expiredRoomsData && expiredRoomsData.getExpiredRooms.map((expiredData) => {
                            return (
                                <>
                                    <Row>
                                        <Col md={6}>
                                            <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{expiredData.roomType} <span style={{ color: '#818080', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}> (Expired)</span></p>
                                            <Row>
                                                <Col>
                                                    <Carousel style={{ width: '150px', height: '150px' }}>
                                                        <Carousel style={{ width: '150px', height: '150px' }}>
                                                            {
                                                                expiredData.imageId.map(expiredImages => {
                                                                    return (

                                                                        <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                            <img
                                                                                className="d-block w-100"
                                                                                src={`https://softbuc.s3.us-east-2.amazonaws.com/${expiredImages}`}
                                                                                alt="First slide"
                                                                                style={{ width: '150px', height: '150px' }}
                                                                            />
                                                                        </Carousel.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                    </Carousel>
                                                </Col>
                                                <Col>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{expiredData.price}</span></p>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{expiredData.area}</span></p>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{expiredData.uploadDate}</span></p>
                                                    <hr />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Image src={ExpiredImg} style={{ width: '50%' }} className="mx-auto d-block" />
                                        </Col>
                                    </Row>
                                    <hr />
                                </>
                            )
                        })
                    }

                </Card.Body>
            </Card>
        </div>
    )
}


