import React, { useState } from 'react'
import { Row, Col, Card, Carousel, Modal, Button, Spinner, Container } from 'react-bootstrap';
import { FcApproval } from "react-icons/fc";
import { FaPlay, FaCheckCircle, FaTimesCircle, FaRegFolderOpen } from "react-icons/fa";
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { INACTIVE_ROOMS, ACTIVE_BTN, ACTIVE_ROOMS, ROOM_BY_ID } from '../../Queries'
import Spinner1 from './Spinner1'
import { SRLWrapper } from "simple-react-lightbox";

export default function InactiveRooms() {
    const landlordId = localStorage.getItem('LandlardId');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [getRoomByIdLazyQuery, { data: roomByIdData, loading: roomByIdLoading }] = useLazyQuery(ROOM_BY_ID);

    const { data: inActiveRoomsData, loading: inActiveRoomsLoading, error: inActiveRoomsError } = useQuery(INACTIVE_ROOMS, {
        variables: {
            getInActiveRoomsLandlordId: `${landlordId}`
        }
    });

    const [activeBtnMutation, { loading: activeBtnMutationLoading }] = useMutation(ACTIVE_BTN, {
        refetchQueries: [
            INACTIVE_ROOMS,
            ACTIVE_ROOMS,
            'getInActiveRooms',
            'getActiveRooms'
        ],
    });


    if (inActiveRoomsError) {
        console.log("inActiveRoomsError", inActiveRoomsError)
    }
    if (inActiveRoomsData) {
        //console.log("inActiveRooms", inActiveRoomsData)
    }

    function activeHandle(rId) {
        activeBtnMutation({
            variables: {
                roomId: `${rId}`
            }
        })
    }

    function fetchRoomById(rId) {
        handleShow()
        getRoomByIdLazyQuery({
            variables: {
                roomId: `${rId}`
            }
        })
    }
console.log(inActiveRoomsData)
    return (
        <div>
            <Card>
                <Card.Body>
                    {inActiveRoomsLoading || activeBtnMutationLoading ? <Spinner1 /> :
                        inActiveRoomsData && inActiveRoomsData.getInActiveRooms.length === 0 ?
                            <Container>
                                <Row>
                                    <Col>
                                        <FaRegFolderOpen style={{ fontSize: '100px' }} className="mx-auto d-block" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5 style={{ color: '#e74c3c', textAlign: 'center', fontFamily: 'DM Sans', marginTop: '10px', fontSize: '30px' }}>No Inactive Rooms Found!!!</h5>
                                    </Col>
                                </Row>
                            </Container>
                            :
                            inActiveRoomsData && inActiveRoomsData.getInActiveRooms.map((inactiveData) => {
                                return (
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{inactiveData.roomType} <span style={{ color: '#818080', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}> (Expired In : 20 days )</span></p>
                                                <Row>
                                                    <Col>
                                                        <Carousel style={{ width: '150px', height: '150px' }}>
                                                            {
                                                                inactiveData.imageId.map(inactiveImages => {
                                                                    return (

                                                                        <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                            <img
                                                                                className="d-block w-100"
                                                                                src={`https://softbuc.s3.us-east-2.amazonaws.com/${inactiveImages}`}
                                                                                alt="First slide"
                                                                                style={{ width: '150px', height: '150px' }}
                                                                            />
                                                                        </Carousel.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Carousel>
                                                    </Col>
                                                    <Col>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{inactiveData.price}</span></p>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{inactiveData.area}</span></p>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{inactiveData.uploadDate}</span></p>
                                                        <hr />
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}><p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}><FcApproval />Upgrade Your Plan</p></Col>
                                                    <Col></Col>
                                                    <Col md={2}><p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => fetchRoomById(inactiveData.id)}>View</p></Col>
                                                    <Col md={3}><p style={{ color: '#2ecc71', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => activeHandle(inactiveData.id)}><FaPlay /> Active</p></Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}></Col>
                                                    <Col md={5}>
                                                        <Card >
                                                            <Card.Body>
                                                                <Card.Text style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }} >
                                                                    Responses
                                                                    <p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '20px' }}>20</p>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Card >
                                                            <Card.Body >
                                                                <Card.Text style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }} >
                                                                    Views
                                                                    <p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '20px' }}>20</p>
                                                                </Card.Text>

                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </>
                                )
                            })
                    }

                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        roomByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <SRLWrapper>
                                            <Carousel style={{ width: '100%', height: '200px' }}>
                                                {
                                                    roomByIdData && roomByIdData.getRoomById.imageId.map(activeImages => {
                                                        return (

                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                    alt="First slide"
                                                                    style={{ width: '100%', height: '200px' }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </SRLWrapper>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}>{roomByIdData && roomByIdData.getRoomById.roomType}</p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Rent: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.price}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Area: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.area}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>City: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.city}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Date: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadDate}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Time: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadTime}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Capacity: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.capacity} Peoples</span></p>
                                    </Col>
                                    <Col style={{ borderLeft: '1px solid rgb(231, 76, 60)' }}>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Bathroom Attatched
                                            {
                                                roomByIdData && roomByIdData.getRoomById.bathroomAttach === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Kitchen Available
                                            {
                                                roomByIdData && roomByIdData.getRoomById.kitchenAvailable === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Water Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.waterBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Electricity Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.electricBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    roomByIdData && roomByIdData.getRoomById.remark === "" ? '' :
                                        <Row>
                                            <Col style={{ background: '#bdc3c7', borderRadius: '10px', marginTop: '5px' }}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Remark: <span style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#000' }}>
                                                    {roomByIdData && roomByIdData.getRoomById.remark}
                                                </span></p>
                                            </Col>
                                        </Row>
                                }

                            </Container>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

