import React from 'react'
import SidebarTenant from './SidebarTenant'
import Navigation2 from '../Navigation2'
import { Redirect } from 'react-router-dom'
import { QUERY_TENANT_BY_ID } from '../../Queries'
import { useQuery } from '@apollo/client'
import TenantDetails from './TenantDetails'
import WaitForConfirm from './WaitForConfirm'

export default function LandlordDashboard() {
    const tenantToken = localStorage.getItem('TenantToken');
    const tenantId = localStorage.getItem('TenantId');

    const { data: tenantByIdData, error: tenantByIdError } = useQuery(QUERY_TENANT_BY_ID, {
        variables: {
            getTenantById: `${tenantId}`
        }
    });
    if (tenantByIdError) {
        console.log("tenantByIdError", tenantByIdError)
    }
    if (tenantByIdData) {
        console.log("tenantByIdData", tenantByIdData)
    }

    if (!tenantToken) {
        return <Redirect to="/" />
    }

    return (
        <div>
            {
                window.scrollTo(0, 0)
            }

            <Navigation2 />
            {
                tenantByIdData && tenantByIdData.getTenantById.status === 'tenantTemp' ?<TenantDetails/> :
                tenantByIdData && tenantByIdData.getTenantById.status === 'pending' ?<WaitForConfirm/> :
                    <SidebarTenant />
            }

        </div>
    )
}
