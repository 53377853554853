import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap'
import { FaCheckDouble, FaSadTear } from "react-icons/fa";

export default function PlanExpired() {


    const [planShow, setPlanShow] = useState(false);
    const handlePlanClose = () => setPlanShow(false);
    const handlePlanShow = () => setPlanShow(true);

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <FaSadTear style={{ fontSize: '100px' }} className="mx-auto d-block" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{ fontSize: '25px', fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '10px', color: '#e74c3c' }}>Your Free Plan is expired!!!</h1>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '10px' }}>
                        <Button className="mx-auto d-block upgradePlanBtn" onClick={() => handlePlanShow()}>
                            Upgrade Plan
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Modal show={planShow} onHide={handlePlanClose} size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Card className="planStandardCard">
                                    <Card.Body>

                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}>Standard Plan</h1>
                                        <h3 style={{ fontFamily: 'DM Sans', fontSize: '20px', textAlign: 'center' }}>₹ 599 /-</h3>
                                        <hr />
                                        <div style={{ fontFamily: 'Dm Sans', fontSize: '18px', lineHeight: '37px', height: '100%' }}>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>90 Days Validity</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>ID Verified Tenants</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Bookings</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Photoshoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Property Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>5 Rooms Listing</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Digitally Promoted</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>24x7 Customer Support</Col>
                                            </Row>
                                            <Row>
                                                <Col style={{ marginTop: '131px' }}>
                                                    <Button variant="secondary" onClick={handlePlanClose} className="standardBtn mx-auto d-block">
                                                        Upgrade
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="planPremiumCard">
                                    <Card.Body>

                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}>Premium Plan</h1>
                                        <h3 style={{ fontFamily: 'DM Sans', fontSize: '20px', textAlign: 'center' }}>₹ 999 /-</h3>
                                        <hr />
                                        <div style={{ fontFamily: 'Dm Sans', fontSize: '18px', lineHeight: '37px' }}>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>180 Days Validity</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>ID Verified Tenants</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Bookings</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Photoshoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Video Shoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Property Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Owner Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Rooms Listing</Col>
                                            </Row>

                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Featured Listing</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Digitally Promoted</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>24x7 Customer Support</Col>
                                            </Row>

                                            <Row>
                                                <Col style={{ marginTop: '20px' }}>
                                                    <Button variant="secondary" onClick={handlePlanClose} className="premiumBtn mx-auto d-block">
                                                        Upgrade
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
