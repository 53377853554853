import { gql } from '@apollo/client'

const QUERY_ALL_ROOMS = gql`
query Query {
  getAllRooms {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`

const QUERY_ALL_ACTIVE_ROOMS = gql`
query Query {
  getAllActiveRooms {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`
const QUERY_LANDLORD_BY_ID = gql`
query Query($landlordId: ID!) {
  getLandlordById(landlordId: $landlordId) {
    id
    fName
    lName
    email
    contact
    address
    city
    state
    pincode
    password
    avatar
    emailVerify
    contactVerify
    status
    plan
    planStartingDate
    planStartingTime
    userRegistrationData
    userRegistrationTime
    roomCount
    verifiedTag
    momentTime
  }
}
`

const ROOM_BY_ID = gql`
query Query($roomId: ID!) {
  getRoomById(roomId: $roomId) {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`
const ACTIVE_ROOMS = gql`
query Query($getActiveRoomsLandlordId: ID!) {
  getActiveRooms(landlordId: $getActiveRoomsLandlordId) {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`
const PENDING_ROOMS = gql`
query Query($getPendingRoomsLandlordId: ID!) {
  getPendingRooms(landlordId: $getPendingRoomsLandlordId) {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`
const INACTIVE_ROOMS = gql`
query Query($getInActiveRoomsLandlordId: ID!) {
  getInActiveRooms(landlordId: $getInActiveRoomsLandlordId) {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`
const EXPIRED_ROOMS = gql`
query Query($getExpiredRoomsLandlordId: ID!) {
  getExpiredRooms(landlordId: $getExpiredRoomsLandlordId) {
    id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`


const CREATE_ROOM = gql`
  mutation Mutation($createRoomRoomInput: roomInput) {
    createRoom(RoomInput: $createRoomRoomInput) {
    id
  }
}
`;

const ACTIVATE_LANDLORD = gql`
  mutation Mutation($activeLandlordLandlordId: ID!, $activeLandlordFirstName: String!, $activeLandlordLastName: String!) {
    activeLandlord(landlordId: $activeLandlordLandlordId, firstName: $activeLandlordFirstName, lastName: $activeLandlordLastName) {
      id
      fName
      lName
      contact
      status
    }
}
`;

const INACTIVE_BTN = gql`
 mutation Mutation($roomId: ID!) {
  inactiveBtn(roomId: $roomId) {
     id
     status
  }
}
`;

const ACTIVE_BTN = gql`
 mutation Mutation($roomId: ID!) {
  activeBtn(roomId: $roomId) {
     id
     status
  }
}
`;


const QUERY_TENANT_BY_ID = gql`
query Query($getTenantById: ID!) {
  getTenantById(getTenantById: $getTenantById) {
    id
    fName
    lName
    occupation
    email
    gender
    contact
    city
    state
    pincode
    password
    avatar
    emailVerify
    contactVerify
    identityType
    identityId
    status
  }
}
`
const ACTIVATE_TENANT = gql`
  mutation ActiveTenantMutation($tenantId: ID!, $firstName: String!, $lastName: String!, $email: String!, $gender: String!, $occupation: String!, $city: String!, $state: String!, $pincode: String!, $identityType: String!, $identityNo: String!) {
  activeTenant(tenantId: $tenantId, firstName: $firstName, lastName: $lastName, email: $email, gender: $gender, occupation: $occupation, city: $city, state: $state, pincode: $pincode, identityType: $identityType, identityNo: $identityNo) {
    id
    fName
    lName
    contact
    status
  }
}
`;
const MUTATION_BOOKING_PAYMENT_DONE = gql`
 mutation Mutation($bookingId: ID!) {
  bookingPaymentDone(bookingId: $bookingId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomAddress
    roomUploadDate
    payment
  }
}
`;

const QUERY_BOOKED_ROOMS = gql`
query Query($tenantId: ID!) {
  getBookedRooms(tenantId: $tenantId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomAddress
    roomArea
    roomUploadDate
    payment
  }
}
`
const QUERY_CONFIRMED_ROOMS = gql`
query Query($tenantId: ID!) {
  getConfirmedRooms(tenantId: $tenantId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomAddress
    roomArea
    roomUploadDate
    payment
  }
}
`
const QUERY_CANCELLED_ROOMS = gql`
query Query($tenantId: ID!) {
  getCancelledRooms(tenantId: $tenantId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomAddress
    roomArea
    roomUploadDate
    payment
  }
}
`

const MUTATION_BOOK_AGAIN = gql`
mutation BookAgainMutation($bookingId: ID!) {
  bookAgain(bookingId: $bookingId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}
`;

const MUTATION_CREATE_BOOKING = gql`
mutation CreateBookingsMutation($bookingInput: bookingInput) {
  createBookings(BookingInput: $bookingInput) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}
`;

const QUERY_CHECK_TENANT_REQUEST = gql`
query Query($tenantsId: ID!, $roomsId: ID!) {
  teanantAlreadyBookingCheck(TenantId: $tenantsId, RoomId: $roomsId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}
`

const QUERY_LANDLORD_BOOKING_REQUEST = gql`
query Query($landlordId: ID!) {
  getLandlordBookingRequest(landlordId: $landlordId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}
`

const MUTATION_CONFIRM_ROOM = gql`
mutation Mutation($bookingId: ID!) {
  landlordRoomConfirm(bookingId: $bookingId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}
`;

const MUTATION_DECLINE_ROOM = gql`
mutation LandlordRoomDeclineMutation($bookingId: ID!) {
  landlordRoomDecline(bookingId: $bookingId) {
    id
    landlordId
    roomId
    tenantId
    bookingDate
    bookingTime
    momentTime
    status
    roomImgKey
    roomType
    roomRent
    roomArea
    roomAddress
    roomUploadDate
    payment
  }
}

`;

const QUERY_SEND_LOGIN_OTP = gql`
query Query($contact: String!, $otp: String!) {
  sendLoginOtp(contact: $contact, otp: $otp)
}
`

const QUERY_SEND_SMS_LANDLORD_ROOM_UNDER_REVIEW = gql`
query Query($landlordContact: String!, $landlordName: String!) {
  landlordRoomUnderReview(landlordContact: $landlordContact, landlordName: $landlordName)
}
`

const QUERY_SEND_SMS_TENANT_ROOM_REQUEST_DONE = gql`
query Query($tenantContact: String!, $tenantName: String!) {
  tenantRoomRequestDone(tenantContact: $tenantContact, tenantName: $tenantName)
}
`
const QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST = gql`
query Query($landlordContact: String!, $landlordName: String!) {
  landlordRoomBookingRequest(landlordContact: $landlordContact, landlordName: $landlordName)
}
`
const QUERY_SEND_SMS_TENANT_PAYMENT_DONE = gql`
query Query($tenantContact: String!, $tenantName: String!) {
  tenantPaymentSuccessful(tenantContact: $tenantContact, tenantName: $tenantName)
}
`
const QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED = gql`
query Query($tenantContact: String!, $tenantName: String!) {
  tenantRoomAccepted(tenantContact: $tenantContact, tenantName: $tenantName)
}
`
const QUERY_SEND_SMS_TENANT_REQUEST_CANCELLED = gql`
query Query($tenantContact: String!, $tenantName: String!) {
  tenantRoomCancelled(tenantContact: $tenantContact, tenantName: $tenantName)
}
`
const QUERY_SEND_SMS_TENANT_DETAILS_TO_LANDLORD = gql`
query Query($landlordContact: String!, $landlordName: String!, $tenantFname: String!, $tenantLname: String!, $tenantContact: String!) {
  tenantDetails(landlordContact: $landlordContact, landlordName: $landlordName, tenantFname: $tenantFname, tenantLname: $tenantLname, tenantContact: $tenantContact)
}
`
const QUERY_SEND_SMS_LANDLORD_DETAILS_TO_TENANT = gql`
query Query($tenantContact: String!, $tenantName: String!, $landlordFname: String!, $landlordLname: String!, $landlordContact: String!) {
  landlordDetails(tenantContact: $tenantContact, tenantName: $tenantName, landlordFname: $landlordFname, landlordLname: $landlordLname, landlordContact: $landlordContact)
}
`

const QUERY_SEND_SMS_LANDLORD_PLAN_ACTIVATION = gql`
query Query($landlordContact: String!, $landlordName: String!, $planName: String!, $planDays: String!) {
  landlordplanActivationSms(landlordContact: $landlordContact, landlordName: $landlordName, planName: $planName, planDays: $planDays)
}
`


const MUTATION_STANDARD_PLAN_UPDATE = gql`
mutation LandlordStandardPlanActivateMutation($landlordId: ID!) {
  landlordStandardPlanActivate(landlordId: $landlordId) {
  id  
  }
}
`;

const MUTATION_PREMIUM_PLAN_UPDATE = gql`
mutation LandlordStandardPlanActivateMutation($landlordId: ID!) {
  landlordPremiumPlanActivate(landlordId: $landlordId) {
  id  
  }
}
`;

const CONTACT_FORM_EMAIL_SEND = gql`
query Query($name: String!, $email: String!, $contact: String!, $subject: String!, $message: String!) {
  sendEmail(name: $name, email: $email, contact: $contact, subject: $subject, message: $message)
}
`

const QUERY_SEARCH_ROOM = gql`
query Query($city: String!, $area: String!, $roomType: String!) {
  searching(city: $city, area: $area, roomType: $roomType) {
  id
    landlordId
    roomType
    roomAddress
    price
    capacity
    bathroomAttach
    area
    city
    kitchenAvailable
    waterBillInclude
    electricBillInclude
    remark
    imageId
    uploadDate
    uploadTime
    status
  }
}
`



export {
  QUERY_ALL_ROOMS,
  CREATE_ROOM,
  QUERY_LANDLORD_BY_ID,
  ACTIVATE_LANDLORD,
  ACTIVE_ROOMS,
  PENDING_ROOMS,
  INACTIVE_ROOMS,
  EXPIRED_ROOMS,
  ACTIVE_BTN,
  INACTIVE_BTN,
  ROOM_BY_ID,
  QUERY_TENANT_BY_ID,
  ACTIVATE_TENANT,
  QUERY_BOOKED_ROOMS,
  QUERY_CONFIRMED_ROOMS,
  QUERY_CANCELLED_ROOMS,
  MUTATION_BOOKING_PAYMENT_DONE,
  MUTATION_BOOK_AGAIN,
  QUERY_ALL_ACTIVE_ROOMS,
  MUTATION_CREATE_BOOKING,
  QUERY_CHECK_TENANT_REQUEST,
  QUERY_LANDLORD_BOOKING_REQUEST,
  MUTATION_CONFIRM_ROOM,
  MUTATION_DECLINE_ROOM,
  QUERY_SEND_LOGIN_OTP,
  QUERY_SEND_SMS_LANDLORD_ROOM_UNDER_REVIEW,
  QUERY_SEND_SMS_TENANT_ROOM_REQUEST_DONE,
  QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST,
  QUERY_SEND_SMS_TENANT_PAYMENT_DONE,
  QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED,
  QUERY_SEND_SMS_TENANT_REQUEST_CANCELLED,
  QUERY_SEND_SMS_TENANT_DETAILS_TO_LANDLORD,
  QUERY_SEND_SMS_LANDLORD_DETAILS_TO_TENANT,
  QUERY_SEND_SMS_LANDLORD_PLAN_ACTIVATION,
  MUTATION_STANDARD_PLAN_UPDATE,
  MUTATION_PREMIUM_PLAN_UPDATE,
  CONTACT_FORM_EMAIL_SEND,
  QUERY_SEARCH_ROOM,
};