import React from 'react'
import {Redirect} from 'react-router-dom';

export default function LandLordLogout() {
    localStorage.clear()
    return(
        <>
        <Redirect to="/" />
        </>
    )
}