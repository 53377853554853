import React from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col, Accordion } from 'react-bootstrap'

export default function Career() {
    return (
        <div>
            <Navigation2 />
            <Container style={{ marginTop: '150px' }}>
                <Row>
                    <Col>
                        <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Open Positions</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>Survey Researchers</Accordion.Header>
                                <Accordion.Body>
                                    <li style={{ fontFamily: 'DM Sans' }}>Contact us for more details.</li>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>React Native Developer</Accordion.Header>
                                <Accordion.Body>
                                    <li style={{ fontFamily: 'DM Sans' }}>Contact us for more details.</li>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
