import React, { useState } from 'react'
import { Card, Form, Container, Col, Row, Button } from 'react-bootstrap'
import '../componentCss/Header.css'
import { useHistory } from "react-router-dom"

export default function Header() {

  //const landlordToken = localStorage.getItem('LandlordToken')
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  //const [showError, setShowError] = useState(false)



  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      let path = `/rooms`;
      history.push(path);
    }

    setValidated(true);
  };


  return (
    <header id="header">
      <div className='intro'>
        <div className='overlay'>
          <Container className="intro-text">
            <Row>
              <Col sm={5}>
                <Card id="headercard">
                  <Card.Body>
                    <Card.Title className="kk">Find Rooms, Flats, Houses, PGs in your City.</Card.Title>
                    <span id="jk">RoomsNearYou provide rooms, flats, houses, PGs for rent without any commission and brokerage.
                    </span>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                          <Form.Control required as="select" className="form-div">
                            <option value="" selected="selected" disabled="disabled">Select your city</option>
                            <option value="Sagar, Madhya Pradesh">Sagar, Madhya Pradesh</option>
                            <option value="Jabalpur, Madhya Pradesh">Jabalpur, Madhya Pradesh</option>
                            <option value="Indore, Madhya Pradesh">Indore, Madhya Pradesh</option>
                            <option value="Bhopal, Madhya Pradesh">Bhopal, Madhya Pradesh</option>
                            {/* <option value="Satna, Madhya Pradesh">Satna, Madhya Pradesh</option>
                            <option value="Varanasi, Uttar Pradesh">Varanasi, Uttar Pradesh</option> */}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid" style={{ fontFamily: 'DM Sans' }}>Please choose city.</Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Button type="submit" className="btn btn-primary mx-auto d-block" id="button">Find Rooms</Button>
                    </Form>

                  </Card.Body>


                </Card>
              </Col>
              {/* <Col sm={7}>
                <h2>
                  Find your room here
                </h2>
              </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    </header>
  )
}