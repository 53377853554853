import React, { useState, useRef } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import AWS from 'aws-sdk'
import { Form, Col, Row, Button, Card, Modal, Container, Alert } from 'react-bootstrap'
import './LandlordCss/UploadRoomsCss.css'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_ROOM, QUERY_LANDLORD_BY_ID, PENDING_ROOMS, QUERY_SEND_SMS_LANDLORD_ROOM_UNDER_REVIEW, QUERY_SEND_SMS_LANDLORD_PLAN_ACTIVATION, MUTATION_STANDARD_PLAN_UPDATE, MUTATION_PREMIUM_PLAN_UPDATE } from '../../Queries'
import uniqid from 'uniqid';
import { FaExclamationTriangle, FaCheckCircle, FaCheckDouble, FaSadTear } from "react-icons/fa";
import Spinner from '../Spinner'
import Resizer from "react-image-file-resizer";
import Logo from '../../logo.png'



const ID = 'AKIA6GB4RFKTM63VVHEK';
const SECRET = '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';
const BUCKET_NAME = 'softbuc';

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET
});



const imageKey = []

export default function UploadRooms() {

    const landlordId = localStorage.getItem('LandlardId');

    const { data: landlordByIdData } = useQuery(QUERY_LANDLORD_BY_ID, {
        variables: {
            landlordId: `${landlordId}`
        }
    });



    const [createRoomMutation] = useMutation(CREATE_ROOM, {
        refetchQueries: [
            PENDING_ROOMS,
            'getPendingRooms'
        ],
    });

    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [planShow, setPlanShow] = useState(false);
    const handlePlanClose = () => setPlanShow(false);
    const handlePlanShow = () => setPlanShow(true);



    const [uploadingDone, setUploadingDone] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [selectedFile, setSelectedFile] = useState(null);

    const [validated, setValidated] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(0);

    const [uploadRoomFirst, setUploadRoomFirst] = useState(false)
    const [finalUploadingDone, setFinalUploadingDone] = useState(false)

    const [sendSmsLandlordRoomUnderReviewLazyQuery] = useLazyQuery(QUERY_SEND_SMS_LANDLORD_ROOM_UNDER_REVIEW);
    const [sendSmsLandlordPlanActivationLazyQuery] = useLazyQuery(QUERY_SEND_SMS_LANDLORD_PLAN_ACTIVATION);

    const [standardPlanUpdateMutation] = useMutation(MUTATION_STANDARD_PLAN_UPDATE);
    const [premiumPlanUpdateMutation] = useMutation(MUTATION_PREMIUM_PLAN_UPDATE);

    let roomType = useRef(null)
    let capacity = useRef(null)
    let bathroomAttatched = useRef(null)
    let kitchenAvailable = useRef(null)
    let waterBillInclude = useRef(null)
    let electricityBillInclude = useRef(null)
    let area = useRef(null)
    let city = useRef(null)
    let price = useRef(null)
    let roomAddress = useRef(null)
    let remark = useRef(null)

    // eslint-disable-next-line no-unused-vars
    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }
    // console.log("DATA", data)

    const handleChangeStatus = ({ meta, file }, status) => {
        // console.log(status, meta, file)
    }


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0,
                uri => {
                    resolve(uri);
                },
                'base64'
            );
        });

    const changeImage = async (files) => {
        try {

            files.map(async (fileData) => {
                setSpin(true)
                const uniqueId = uniqid()
                const file = fileData.file;
                const image = await resizeFile(file);


                var byteString = atob(image.split(',')[1]);

                // separate out the mime component
                var mimeString = image.split(',')[0].split(':')[1].split(';')[0]

                // write the bytes of the string to an ArrayBuffer
                var ab = new ArrayBuffer(byteString.length);

                // create a view into the buffer
                var ia = new Uint8Array(ab);

                // set the bytes of the buffer to the correct values
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                // write the ArrayBuffer to a blob, and you're done
                var blob = new Blob([ab], { type: mimeString });

                //console.log("IMAGE", blob);
                var uniqueFileName = uniqueId + "-" + uniqueId + ".jpeg";

                imageKey.push(uniqueFileName)


                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueId + "-" + uniqueId + ".jpeg",
                    Body: blob,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    }
                    setSpin(false)
                    setUploadingDone(true)
                    handleClose()
                });
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else if (imageKey.length === 0) {
            event.preventDefault();
            event.stopPropagation();
            setUploadRoomFirst(true)
        }
        else {
            event.preventDefault();

            let today = new Date();
            let dd = today.getDate();

            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();

            const DateNow = dd + '/' + mm + '/' + yyyy;


            let time = new Date();
            const timeNow = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

            const RoomType = roomType.current.value;
            const Capacity = capacity.current.value;
            const BathroomAttatched = bathroomAttatched.current.value;
            const KitchenAvailable = kitchenAvailable.current.value;
            const WaterBillInclude = waterBillInclude.current.value;
            const ElectricityBillInclude = electricityBillInclude.current.value;
            const Area = area.current.value;
            const City = city.current.value;
            const Price = price.current.value;
            const RoomAddress = roomAddress.current.value;
            const Remark = remark.current.value;


            const ReplaceAddress = RoomAddress.replace(/(\r\n|\n|\r)/gm, "");
            const ReplaceRemark = Remark.replace(/(\r\n|\n|\r)/gm, "");

            createRoomMutation({
                variables: {
                    createRoomRoomInput: {
                        roomType: `${RoomType}`,
                        capacity: `${Capacity}`,
                        bathroomAttach: `${BathroomAttatched}`,
                        kitchenAvailable: `${KitchenAvailable}`,
                        waterBillInclude: `${WaterBillInclude}`,
                        electricBillInclude: `${ElectricityBillInclude}`,
                        area: `${Area}`,
                        city: `${City}`,
                        price: `${Price}`,
                        roomAddress: `${ReplaceAddress}`,
                        remark: `${ReplaceRemark}`,
                        imageId: imageKey,
                        landlordId: `${landlordId}`,
                        status: "pending",
                        uploadDate: `${DateNow}`,
                        uploadTime: `${timeNow}`,
                    }
                }
            })

            sendSmsLandlordRoomUnderReviewLazyQuery({
                variables: {
                    landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                    landlordName: `${landlordByIdData.getLandlordById.fName}`,
                }
            })
            setFinalUploadingDone(true)
        }

        setValidated(true);
    };

    function refreshPage() {
        window.location.reload()
    }

    if (landlordByIdData && landlordByIdData.getLandlordById.plan === 'Free Plan') {
        var roomCountVar = 2
    }
    if (landlordByIdData && landlordByIdData.getLandlordById.plan === 'Standard Plan') {
        // eslint-disable-next-line no-redeclare
        var roomCountVar = 5
    }
    if (landlordByIdData && landlordByIdData.getLandlordById.plan === 'Premium Plan') {
        // eslint-disable-next-line no-redeclare
        var roomCountVar = 100
    }

    const standardPlanPay = 599;
    const premiumPlanPay = 999;
    //load script
    const loadScript = (src) => {

        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resovle(true);
            };
            script.onerror = () => {
                resovle(false);
            };
            document.body.appendChild(script);
        });
    };

    const standardPlanBuyHandle = async (amount) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("You are offline... Failed to load Payment Gateway");
            return;
        }
        const options = {
            key: "rzp_live_ST4iBKTPVGWIQG",
            currency: "INR",
            amount: amount * 100,
            name: "RoomsNearYou",
            description: "Payment for Standard Plan",
            image: `${Logo}`,

            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert("Payment Successfully");


                standardPlanUpdateMutation({
                    variables: {
                        landlordId: `${landlordByIdData.getLandlordById.id}`
                    }
                })

                sendSmsLandlordPlanActivationLazyQuery({
                    variables: {
                        landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                        landlordName: `${landlordByIdData.getLandlordById.fName}`,
                        planName: "Standard",
                        planDays: "90",
                    }
                })


                refreshPage()
            },
            prefill: {
                name: "RoomsNearYou",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        handlePlanClose()
    }


    const premiumPlanBuyHandle = async (amount) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("You are offline... Failed to load Payment Gateway");
            return;
        }
        const options = {
            key: "rzp_live_ST4iBKTPVGWIQG",
            currency: "INR",
            amount: amount * 100,
            name: "RoomsNearYou",
            description: "Payment for Premium Plan",
            image: `${Logo}`,

            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert("Payment Successfully");

                premiumPlanUpdateMutation({
                    variables: {
                        landlordId: `${landlordByIdData.getLandlordById.id}`
                    }
                })

                sendSmsLandlordPlanActivationLazyQuery({
                    variables: {
                        landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                        landlordName: `${landlordByIdData.getLandlordById.fName}`,
                        planName: "Premium",
                        planDays: "180",
                    }
                })

                refreshPage()

            },
            prefill: {
                name: "RoomsNearYou",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        handlePlanClose()
    }

    console.log(landlordByIdData)

    return (
        <div>

            {
                landlordByIdData && landlordByIdData.getLandlordById.roomCount === roomCountVar ?
                    <Container>
                        <Row>
                            <Col>
                                <FaSadTear style={{ fontSize: '100px' }} className="mx-auto d-block" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h1 style={{ fontSize: '25px', fontFamily: 'Dm Sans', fontWeight: 'bold', textAlign: 'center', marginTop: '10px', color: '#e74c3c' }}>Your Limit is exceed after uploaded 2 rooms!!!</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginTop: '10px' }}>
                                <Button className="mx-auto d-block upgradePlanBtn" onClick={() => handlePlanShow()}>
                                    Upgrade Plan
                                </Button>
                            </Col>
                        </Row>
                    </Container>

                    :
                    <Card>
                        <Card.Body>
                            {
                                finalUploadingDone ?
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Alert variant='success' style={{ textAlign: 'center', fontFamily: 'DM Sans' }}>
                                                    Rooom Uploaded Succefully and in the <span style={{ color: '#e74c3c' }}>Pending Rooms Tab</span>. <span style={{ fontWeight: 'bold' }}>Your room will be reviewed and confirmed under 24 hours.</span>
                                                </Alert>

                                                <Button type="submit" className="mx-auto d-block uploadBtn" onClick={() => refreshPage()}>Upload More</Button>
                                            </Col>
                                        </Row>
                                    </Container> :
                                    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                                        <Row className="mb-2">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Room Type</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={roomType}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Room Type</option>
                                                    <option value="Single Room">Single Room</option>
                                                    <option value="Flat">Flat</option>
                                                    <option value="Apartment">Apartment</option>
                                                    <option value="Hostel (Girls/Boys Both)">Hostel (Girls/Boys Both)</option>
                                                    <option value="Hostel (Only Girls)">Hostel (Only Girls)</option>
                                                    <option value="Hostel (Only Boys)">Hostel (Only Boys)</option>
                                                    <option value="Shop">Shop</option>
                                                    <option value="Commercial">Commercial</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Room Type</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Capacity</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={capacity}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Room Capacity</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="6">4</option>
                                                    <option value="5">5</option>
                                                    <option value="More than 5">More than 5</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Room Capacity</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-2">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Bathroom Attached</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={bathroomAttatched}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Yes/No</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Kitchen Available</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={kitchenAvailable}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Yes/No</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-2">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Water Bill Included</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={waterBillInclude}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Yes/No</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Electricity Bill Included</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={electricityBillInclude}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Yes/No</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>



                                        <Row className="mb-2">
                                            {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Area/Ward</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={area}>
                                                    <option value="" selected="selected" disabled="disabled">Choose Area/Ward</option>
                                                    <option value="Ambedkar Ward">Ambedkar Ward</option>
                                                    <option value="Bariyaghat Ward">Bariyaghat Ward</option>
                                                    <option value="Bhagatsing Ward">Bhagatsing Ward</option>
                                                    <option value="Bhagraj Ward">Bhagraj Ward</option>
                                                    <option value="Bhagwanganj Ward">Bhagwanganj Ward</option>
                                                    <option value="Chakraghat Ward">Chakraghat Ward</option>
                                                    <option value="Chandrashekhar Ward">Chandrashekhar Ward</option>
                                                    <option value="Civil Line Ward">Civil Line Ward</option>
                                                    <option value="Dayanand Ward">Dayanand Ward</option>
                                                    <option value="Gandhi Chouk Ward">Gandhi Chouk Ward</option>
                                                    <option value="Gopalganj Ward">Gopalganj Ward</option>
                                                    <option value="Guru Govindsingh Ward">Guru Govindsingh Ward</option>
                                                    <option value="Harisingh Gour Ward">Harisingh Gour Ward</option>
                                                    <option value="Indra Nagar Ward">Indra Nagar Ward</option>
                                                    <option value="Itwari">Itwari</option>
                                                    <option value="Javaharganj Ward">Javaharganj Ward</option>
                                                    <option value="Kakaganj Ward">Kakaganj Ward</option>
                                                    <option value="Katra Ward">Katra Ward</option>
                                                    <option value="Keshavganj Ward">Keshavganj Ward</option>
                                                    <option value="Krishan Ganj Ward">Krishan Ganj Ward</option>
                                                    <option value="Laxmipura Ward">Laxmipura Ward</option>
                                                    <option value="Madhukar Shah Ward">Madhukar Shah Ward</option>
                                                    <option value="Makroniya Ward">Makroniya Ward</option>
                                                    <option value="Mohan Nagar">Mohan Nagar</option>
                                                    <option value="Motinagar Ward">Motinagar Ward</option>
                                                    <option value="Naryavli Ward">Naryavli Ward</option>
                                                    <option value="Pantnagar Ward">Pantnagar Ward</option>
                                                    <option value="Parkota Ward">Parkota Ward</option>
                                                    <option value="Purviyau Ward">Purviyau Ward</option>
                                                    <option value="Rajiv Nagar">Rajiv Nagar</option>
                                                    <option value="Rampura Ward">Rampura Ward</option>
                                                    <option value="Ravishankar Ward">Ravishankar Ward</option>
                                                    <option value="Sant Kabir Ward">Sant Kabir Ward</option>
                                                    <option value="Sant Kavarram Ward">Sant Kavarram Ward</option>
                                                    <option value="Sant Ravidas Ward">Sant Ravidas Ward</option>
                                                    <option value="Shanichri Ward">Shanichri Ward</option>
                                                    <option value="Shastri Ward">Shastri Ward</option>
                                                    <option value="Shivaji Ward">Shivaji Ward</option>
                                                    <option value="Shukrawari Ward">Shukrawari Ward</option>
                                                    <option value="Subedar Ward">Subedar Ward</option>
                                                    <option value="Subhashnagar Ward">Subhashnagar Ward</option>
                                                    <option value="Tilakganj Ward">Tilakganj Ward</option>
                                                    <option value="Tili Ward">Tili Ward</option>
                                                    <option value="Vallabh Nagar Ward">Vallabh Nagar Ward</option>
                                                    <option value="Vithalnagar Ward">Vithalnagar Ward</option>
                                                    <option value="Vivekanand Ward">Vivekanand Ward</option>
                                                    <option value="Vrindavan Ward">Vrindavan Ward</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group> */}
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Form.Label className="formLable">City</Form.Label>
                                                <Form.Control required as="select" className="formSelect" ref={city}>
                                                    <option value="" selected="selected" disabled="disabled">Choose City</option>
                                                    <option value="Sagar, Madhya Pradesh">Sagar, Madhya Pradesh</option>
                                                    <option value="Jabalpur, Madhya Pradesh">Jabalpur, Madhya Pradesh</option>
                                                    <option value="Indore, Madhya Pradesh">Indore, Madhya Pradesh</option>
                                                    <option value="Bhopal, Madhya Pradesh">Bhopal, Madhya Pradesh</option>
                                                    <option value="Satna, Madhya Pradesh">Satna, Madhya Pradesh</option>
                                                    <option value="Varanasi, Uttar Pradesh">Varanasi, Uttar Pradesh</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Choose Yes/No</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Form.Label className="formLable">Area</Form.Label>
                                                <Form.Control required type="text" placeholder="Enter Area" className="formSelect"  ref={area}/>
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Enter Area</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                <Form.Label className="formLable">Price (per month)</Form.Label>
                                                <Form.Control required type="number" placeholder="Enter Price (Per Month)" className="formSelect" ref={price} />
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Enter Price</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>



                                        <Row className="mb-2">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Room Address</Form.Label>
                                                <Form.Control required as="textarea" placeholder="Enter Room Address" className="formSelect" ref={roomAddress} />
                                                <Form.Control.Feedback className="formLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="formInvalid">Enter Room Address</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="formLable">Remark</Form.Label>
                                                <Form.Control as="textarea" placeholder="Enter Remark" className="formSelect" ref={remark} />
                                            </Form.Group>
                                        </Row>
                                        <hr />
                                        <Row>
                                            {
                                                uploadingDone ? <p style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', color: 'green' }}><FaCheckCircle /> Room's pictures are uploaded succefully!!</p> :
                                                    <div>
                                                        <Col md={3} style={{ fontFamily: 'DM Sans', fontSize: '13px' }}>
                                                            Upload Room's Pictures
                                                        </Col>
                                                        <Col>
                                                            <Button className="picUploadBtn" onClick={() => handleShow()}>Choose</Button>
                                                        </Col>
                                                        {
                                                            uploadRoomFirst ? <p style={{ textAlign: 'center', marginTop: '10px', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', color: 'red' }}><FaExclamationTriangle /> Upload room's pictures first!!!</p> : ''
                                                        }
                                                    </div>
                                            }

                                        </Row>
                                        <hr />


                                        <Form.Group className="mb-3">
                                            <Form.Check required label="Agree to terms and conditions" feedback="You must agree before submitting." feedbackType="invalid" className="agreeToTerms" />
                                        </Form.Group>

                                        <Button type="submit" className="uploadBtn">Upload</Button>
                                    </Form>
                            }
                        </Card.Body>
                    </Card>
            }


            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        spin ? <Spinner className="mx-auto d-block" style={{ textAlign: 'center' }} /> :
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                onSubmit={changeImage}
                                accept="image/*"
                                styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                                inputContent="Drop or Choose Rooms Pictures"
                            />
                    }


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="modalCancelBtn">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={planShow} onHide={handlePlanClose} size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Card className="planStandardCard">
                                    <Card.Body>

                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}>Standard Plan</h1>
                                        <h3 style={{ fontFamily: 'DM Sans', fontSize: '20px', textAlign: 'center' }}>₹ 599 /-</h3>
                                        <hr />
                                        <div style={{ fontFamily: 'Dm Sans', fontSize: '18px', lineHeight: '37px', height: '100%' }}>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>90 Days Validity</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>ID Verified Tenants</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Bookings</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Photoshoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Property Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>5 Rooms Listing</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Digitally Promoted</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>24x7 Customer Support</Col>
                                            </Row>
                                            <Row>
                                                <Col style={{ marginTop: '131px' }}>
                                                    <Button variant="secondary" onClick={() => standardPlanBuyHandle(standardPlanPay)} className="standardBtn mx-auto d-block">
                                                        Upgrade
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="planPremiumCard">
                                    <Card.Body>

                                        <h1 style={{ fontFamily: 'DM Sans', fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}>Premium Plan</h1>
                                        <h3 style={{ fontFamily: 'DM Sans', fontSize: '20px', textAlign: 'center' }}>₹ 999 /-</h3>
                                        <hr />
                                        <div style={{ fontFamily: 'Dm Sans', fontSize: '18px', lineHeight: '37px' }}>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>180 Days Validity</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>ID Verified Tenants</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Bookings</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Photoshoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Expert Video Shoot</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Property Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Verified Owner Tag</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Unlimited Rooms Listing</Col>
                                            </Row>

                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Featured Listing</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>Digitally Promoted</Col>
                                            </Row>
                                            <Row>
                                                <Col md={2}><FaCheckDouble style={{ color: 'rgb(63, 219, 177)' }} /></Col>
                                                <Col>24x7 Customer Support</Col>
                                            </Row>

                                            <Row>
                                                <Col style={{ marginTop: '20px' }}>
                                                    <Button variant="secondary" onClick={() => premiumPlanBuyHandle(premiumPlanPay)} className="premiumBtn mx-auto d-block">
                                                        Upgrade
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

