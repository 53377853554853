import React, { useState } from 'react'
import { Row, Col, Card, Carousel, Button, Spinner, Container, Modal, Image } from 'react-bootstrap';
import fireIcon from './fireicon.svg';
import Discount from '../../discount.png'
import './TenantCss/ConfirmedRoomCss.css'
import {
    QUERY_CONFIRMED_ROOMS, ROOM_BY_ID,
    MUTATION_BOOKING_PAYMENT_DONE,
    QUERY_LANDLORD_BY_ID,
    QUERY_TENANT_BY_ID,
    QUERY_SEND_SMS_TENANT_PAYMENT_DONE,
    QUERY_SEND_SMS_TENANT_DETAILS_TO_LANDLORD,
    QUERY_SEND_SMS_LANDLORD_DETAILS_TO_TENANT,
} from '../../Queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { FaCheckCircle, FaTimesCircle, FaRegFolderOpen } from "react-icons/fa";
import { SRLWrapper } from "simple-react-lightbox";
import keyHand from '../../keyHand.png';
import Blast from '../../blast.png'
import Logo from '../../logo.png'

export default function ConfirmedRooms() {

    const tenantId = localStorage.getItem('TenantId');
    const { data: tenantByIdData } = useQuery(QUERY_TENANT_BY_ID, {
        variables: {
            getTenantById: `${tenantId}`
        }
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [paymentShow, setPaymentShow] = useState(false);
    const handlePaymentClose = () => setPaymentShow(false);
    const handlePaymentShow = () => setPaymentShow(true);

    const [ownerShow, setOwnerShow] = useState(false);
    const handleOwnerClose = () => setOwnerShow(false);
    const handleOwnerShow = () => setOwnerShow(true);

    const [bookingId, setBookingId] = useState('')

    const [getRoomByIdLazyQuery, { data: roomByIdData, loading: roomByIdLoading }] = useLazyQuery(ROOM_BY_ID);
    const [sendSmsTenantPaymentDoneLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_PAYMENT_DONE);
    const { data: confirmedRoomData, loading: confirmedRoomLoading, error: confirmedRoomError } = useQuery(QUERY_CONFIRMED_ROOMS, {
        variables: {
            tenantId: `${tenantId}`
        }
    });
    if (confirmedRoomError) {
        console.log("tenantByIdError", confirmedRoomError)
    }
    if (confirmedRoomData) {
        //console.log("ConfirmedData", confirmedRoomData.getConfirmedRooms)
    }

    function fetchRoomById(rId) {
        handleShow()
        getRoomByIdLazyQuery({
            variables: {
                roomId: `${rId}`
            }
        })
    }

    const [getLandlordByIdLazyQuery, { data: landlordByIdData, loading: landlordByIdLoading }] = useLazyQuery(QUERY_LANDLORD_BY_ID);
    const [getTenantByIdLazyQuery, { data: tenantByIdDataLazy, loading: tenantByIdLoadingLazy }] = useLazyQuery(QUERY_TENANT_BY_ID);

    const [sendSmsTenantDetailsLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_DETAILS_TO_LANDLORD);
    const [sendSmsLandlordDetailsLazyQuery] = useLazyQuery(QUERY_SEND_SMS_LANDLORD_DETAILS_TO_TENANT);



    const [bookingPaymentDoneBtnMutation] = useMutation(MUTATION_BOOKING_PAYMENT_DONE, {
        refetchQueries: [
            QUERY_CONFIRMED_ROOMS,
            'getConfirmedRooms',
        ],
    });

    function paymentModalShow(bId, landlordId) {
        setBookingId(bId)

        getLandlordByIdLazyQuery({
            variables: {
                landlordId: `${landlordId}`
            }
        })
        getTenantByIdLazyQuery({
            variables: {
                getTenantById: `${tenantId}`
            }
        })


        handlePaymentShow()
    }

    //payment init
    const tenantPay = 11;
    // var formatter = new Intl.NumberFormat("en-US", {
    //     style: "currency",
    //     currency: "INR",
    // });

    //load script
    const loadScript = (src) => {

        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resovle(true);
            };
            script.onerror = () => {
                resovle(false);
            };
            document.body.appendChild(script);
        });
    };


    const bookingPaymentDoneHandle = async (amount) => {


        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("You are offline... Failed to load Payment Gateway");
            return;
        }
        const options = {
            key: "rzp_live_ST4iBKTPVGWIQG",
            currency: "INR",
            amount: amount * 100,
            name: "RoomsNearYou",
            description: "Payment for get Landlord's Details",
            image: `${Logo}`,

            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert("Payment Successfully");
                bookingPaymentDoneBtnMutation({
                    variables: {
                        bookingId: `${bookingId}`
                    }
                })

                sendSmsTenantPaymentDoneLazyQuery({
                    variables: {
                        tenantContact: `${tenantByIdData.getTenantById.contact}`,
                        tenantName: `${tenantByIdData.getTenantById.fName}`,
                    }
                })

                sendSmsTenantDetailsLazyQuery({
                    variables: {
                        landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                        landlordName: `${landlordByIdData.getLandlordById.fName}`,
                        tenantFname: `${tenantByIdDataLazy.getTenantById.fName}`,
                        tenantLname: `${tenantByIdDataLazy.getTenantById.lName}`,
                        tenantContact: `${tenantByIdDataLazy.getTenantById.contact}`
                    }
                })

                sendSmsLandlordDetailsLazyQuery({
                    variables: {
                        tenantContact: `${tenantByIdDataLazy.getTenantById.contact}`,
                        tenantName: `${tenantByIdDataLazy.getTenantById.fName}`,
                        landlordFname: `${landlordByIdData.getLandlordById.fName}`,
                        landlordLname: `${landlordByIdData.getLandlordById.lName}`,
                        landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                    }
                })


            },
            prefill: {
                name: "RoomsNearYou",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();


        handlePaymentClose()
    }

    function fetchQwner(oId) {
        handleOwnerShow()
        getLandlordByIdLazyQuery({
            variables: {
                landlordId: `${oId}`
            }
        })
    }
    console.log("Landlord Data", landlordByIdData)
    // console.log("Tenant Data", tenantByIdDataLazy)
    return (
        <div>
            <Card>
                <Card.Body>
                    {
                        confirmedRoomLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block" variant="danger" style={{ fontSize: '13px', height: '20px', width: '20px' }}>
                            </Spinner> :
                            confirmedRoomData && confirmedRoomData.getConfirmedRooms.length === 0 ?
                                <Container>
                                    <Row>
                                        <Col>
                                            <FaRegFolderOpen style={{ fontSize: '100px' }} className="mx-auto d-block" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5 style={{ color: '#e74c3c', textAlign: 'center', fontFamily: 'DM Sans', marginTop: '10px', fontSize: '30px' }}>No Confirmed Rooms Found!!!</h5>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                confirmedRoomData && confirmedRoomData.getConfirmedRooms.map(confirmData => {
                                    return (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{confirmData.roomType}</p>
                                                    <Row>
                                                        <Col>
                                                            <Carousel style={{ width: '150px', height: '150px' }}>
                                                                {
                                                                    confirmData.roomImgKey.map(confirmImages => {
                                                                        return (

                                                                            <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                                <img
                                                                                    className="d-block w-100"
                                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${confirmImages}`}
                                                                                    alt="First slide"
                                                                                    style={{ width: '150px', height: '150px' }}
                                                                                />
                                                                            </Carousel.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Carousel>
                                                        </Col>
                                                        <Col>
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{confirmData.roomRent}</span></p>
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{confirmData.roomArea}</span></p>
                                                            {
                                                                confirmData.payment === 'true' ?
                                                                <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Address:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{confirmData.roomAddress}</span></p> : ""
                                                            }
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{confirmData.roomUploadDate}</span></p>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col md={10}><p style={{ color: '#818080', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>
                                                            <img
                                                                src={fireIcon}
                                                                alt="First slide"
                                                                style={{ width: '20px', height: '20px' }} /> <span style={{ color: '#303030', fontFamily: 'DM Sans', fontSize: '15px' }}>50</span> people already view this property, Hurry Up !! </p></Col>
                                                        <Col md={2}><p style={{ color: '#ff7f50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => fetchRoomById(confirmData.roomId)}>View</p></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Image src={Blast} className="mx-auto d-block" />
                                                            <h1 style={{ textAlign: 'center', color: '#8e44ad', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '17px' }}>Congratulation</h1>
                                                            <p style={{ textAlign: 'center', color: '#2ecc71', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Your Request is confirmed by landlord.</p>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={6}> </Col>
                                                        <Col md={6}>
                                                            {
                                                                confirmData.payment === "false" ?
                                                                    <Button className="OwnerContact-btn" onClick={() => paymentModalShow(confirmData.id, confirmData.landlordId)}>Owner's Contact Details </Button> :
                                                                    <Button className="OwnerContact-btn" onClick={() => fetchQwner(confirmData.landlordId)}>Owner's Details</Button>
                                                            }

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                })
                    }

                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        roomByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <SRLWrapper>
                                            <Carousel style={{ width: '100%', height: '200px' }}>
                                                {
                                                    roomByIdData && roomByIdData.getRoomById.imageId.map(activeImages => {
                                                        return (

                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                    alt="First slide"
                                                                    style={{ width: '100%', height: '200px' }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </SRLWrapper>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}>{roomByIdData && roomByIdData.getRoomById.roomType}</p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Rent: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.price}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Area: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.area}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Date: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadDate}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Time: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadTime}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Capacity: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.capacity} Peoples</span></p>
                                    </Col>
                                    <Col style={{ borderLeft: '1px solid rgb(231, 76, 60)' }}>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Bathroom Attatched
                                            {
                                                roomByIdData && roomByIdData.getRoomById.bathroomAttach === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Kitchen Available
                                            {
                                                roomByIdData && roomByIdData.getRoomById.kitchenAvailable === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Water Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.waterBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Electricity Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.electricBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    roomByIdData && roomByIdData.getRoomById.remark === "" ? '' :
                                        <Row>
                                            <Col style={{ background: '#bdc3c7', borderRadius: '10px', marginTop: '5px' }}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Remark: <span style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#000' }}>
                                                    {roomByIdData && roomByIdData.getRoomById.remark}
                                                </span></p>
                                            </Col>
                                        </Row>
                                }

                            </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={paymentShow} onHide={handlePaymentClose}>
                <Modal.Header closeButton>
                    <h1 style={{fontFamily:'DM Sans', fontSize:'25px', marginTop:'10px'}}>80% Discount <Image src={Discount} style={{width:'60px', marginTop:'-10px'}}/></h1>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        {
                            landlordByIdLoading ?
                                <>
                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </> :
                                tenantByIdLoadingLazy ?
                                    <>
                                        <Spinner animation="border" role="status" className="mx-auto d-block">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </> :
                                    <>
                                        <Row>
                                            <Col>
                                                <Image src={keyHand} style={{ height: '150px', width: '150px' }} />
                                            </Col>
                                            <Col>
                                                <p style={{ fontFamily: 'DM Sans', fontSize: '18px' }}>Pay <span style={{ color: '#d35400', fontWeight: 'bold' }}><strike>₹51</strike> ₹11</span> for sharing Owner's Details and Room's Address</p>
                                                <Button variant="secondary" onClick={() => bookingPaymentDoneHandle(tenantPay)} className="modalPayBtn">
                                                    Pay
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                        }
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePaymentClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ownerShow} onHide={handleOwnerClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            landlordByIdLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                <Row>
                                    <Col>
                                        <Image src={keyHand} style={{ height: '150px', width: '150px' }} />
                                    </Col>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold' }}>Name: <span style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'normal' }}>{landlordByIdData && landlordByIdData.getLandlordById.fName} {landlordByIdData && landlordByIdData.getLandlordById.lName}</span></p>

                                        <p style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold' }}>Email: <span style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'normal' }}>{landlordByIdData && landlordByIdData.getLandlordById.email}</span></p>

                                        <p style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold' }}>Contact: <span style={{ fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'normal' }}>+91-{landlordByIdData && landlordByIdData.getLandlordById.contact}</span></p>
                                    </Col>
                                </Row>

                        }

                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleOwnerClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
