import { Nav, Tab, Row, Col, Container, Card } from 'react-bootstrap';
import './LandlordCss/SideBarCss.css'
import { FcBusinessman, FcApproval } from "react-icons/fc";
import { QUERY_LANDLORD_BY_ID } from '../../Queries'
import { useQuery } from '@apollo/client'

import ActiveRooms from './ActiveRooms';
import InactiveRooms from './InactiveRooms';
import ExpiredRooms from './ExpiredRooms';
import PendingRooms from './PendingRooms';
import UploadRooms from './UploadRooms'
import PlanExpired from './PlanExpired';
import RoomRequests from './RoomRequests';

import { FaUserClock } from "react-icons/fa";

import Spinner1 from './Spinner1';

import moment from 'moment'

function SidebarLandlord() {

    const landlordId = localStorage.getItem('LandlardId');

    const { data: landlordByIdData, loading: landlordByIdLoading, error: landlordByIdError } = useQuery(QUERY_LANDLORD_BY_ID, {
        variables: {
            landlordId: `${landlordId}`
        }
    });
    if (landlordByIdError) {
        console.log("landlordByIdError", landlordByIdError)
    }
    if (landlordByIdData) {
        // console.log("landlordById", landlordByIdData.getLandlordById.status)
    }


    const todayDate = new Date();
    const todayDateFormate = moment(todayDate).format('YYYY-MM-DD');


    if (landlordByIdData && landlordByIdData.getLandlordById.plan === 'Free Plan') {
        const landlordFreePlanStartDate = landlordByIdData && landlordByIdData.getLandlordById.momentTime
        const landlordFreePlanStartDateFormat = moment(landlordFreePlanStartDate).format('YYYY/MM/DD');

        const dateAfter30 = moment(landlordFreePlanStartDateFormat).add(30, 'days');
        const dateAfter30Format = moment(dateAfter30).format('YYYY-MM-DD');

        var a = moment(dateAfter30Format);
        var b = moment(todayDateFormate);
        var DaysLeftdate = a.diff(b, 'days')
    }
    if(landlordByIdData && landlordByIdData.getLandlordById.plan === 'Standard Plan'){
        const landlordFreePlanStartDate = landlordByIdData && landlordByIdData.getLandlordById.momentTime
        const landlordFreePlanStartDateFormat = moment(landlordFreePlanStartDate).format('YYYY/MM/DD');

        const dateAfter30 = moment(landlordFreePlanStartDateFormat).add(90, 'days');
        const dateAfter30Format = moment(dateAfter30).format('YYYY-MM-DD');
        // eslint-disable-next-line no-redeclare
        var a = moment(dateAfter30Format);
        // eslint-disable-next-line no-redeclare
        var b = moment(todayDateFormate);
        // eslint-disable-next-line no-redeclare
        var DaysLeftdate = a.diff(b, 'days')
    }
    if(landlordByIdData && landlordByIdData.getLandlordById.plan === 'Premium Plan'){
        const landlordFreePlanStartDate = landlordByIdData && landlordByIdData.getLandlordById.momentTime
        const landlordFreePlanStartDateFormat = moment(landlordFreePlanStartDate).format('YYYY/MM/DD');

        const dateAfter30 = moment(landlordFreePlanStartDateFormat).add(180, 'days');
        const dateAfter30Format = moment(dateAfter30).format('YYYY-MM-DD');

        // eslint-disable-next-line no-redeclare
        var a = moment(dateAfter30Format);
        // eslint-disable-next-line no-redeclare
        var b = moment(todayDateFormate);
        // eslint-disable-next-line no-redeclare
        var DaysLeftdate = a.diff(b, 'days')
    }


console.log(landlordByIdData)
    return (
        <>
            <Container className="sidebarContainer">
                {
                    DaysLeftdate === 0 ?
                        <Row>
                            <Col md={3}>
                                <Card className="leftCard">
                                    <Card.Body>
                                        <FcBusinessman className="mx-auto d-block" style={{ fontSize: '4rem' }} />
                                        {
                                            landlordByIdLoading ? <Spinner1 /> :
                                                <>
                                                    <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#303030' }}>{landlordByIdData.getLandlordById.fName} {landlordByIdData.getLandlordById.lName}</h4>
                                                    <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '12px', color: '#818080' }}><FcApproval /> Free Plan</p>
                                                    <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#e74c3c', marginTop: '-10px' }}><FaUserClock style={{ marginTop: '-3px', fontSize: '17px', fontWeight: 'bold', color: '#e74c3c' }} /> {DaysLeftdate} Days Left</p>
                                                </>
                                        }

                                        <hr />
                                        <Nav activeKey="uploadRooms" style={{ width: '60%' }}>
                                        </Nav>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md={9}>
                                <Card className="rightCard">
                                    <Card.Body>

                                        <PlanExpired />

                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                        :
                        <Tab.Container defaultActiveKey="uploadRooms">
                            <Row>
                                <Col md={3}>
                                    <Card className="leftCard">
                                        <Card.Body>
                                            <FcBusinessman className="mx-auto d-block" style={{ fontSize: '4rem' }} />
                                            {
                                                landlordByIdLoading ? <Spinner1 /> :
                                                    <>
                                                        <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#303030' }}>{landlordByIdData.getLandlordById.fName} {landlordByIdData.getLandlordById.lName}</h4> 
                                                        {
                                                            landlordByIdData && landlordByIdData.getLandlordById.plan === 'Free Plan' ?
                                                            <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '12px', color: '#818080' }}><FcApproval /> Free Plan</p> :
                                                            landlordByIdData && landlordByIdData.getLandlordById.plan === 'Standard Plan' ?
                                                            <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '14px', color: '#fff', backgroundColor:'#2980b9', padding:'5px', borderRadius:'50px', letterSpacing:'3px', textShadow:' 1px 1px #95a5a6' }}> Standard Plan</p> :
                                                            landlordByIdData && landlordByIdData.getLandlordById.plan === 'Premium Plan' ?
                                                            <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '14px', color: '#fff', backgroundColor:'#f1c40f', padding:'5px', borderRadius:'50px', letterSpacing:'3px', textShadow:' 1px 1px #95a5a6' }}> Premium Plan</p> : "Error!!!"
                                                        }
                                                        <p style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#e74c3c', marginTop: '-10px' }}><FaUserClock style={{ marginTop: '-3px', fontSize: '17px', fontWeight: 'bold', color: '#e74c3c' }} /> {DaysLeftdate} Days Left</p>
                                                    </>
                                            }

                                            <hr />
                                            <Nav activeKey="uploadRooms" style={{ width: '60%' }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="uploadRooms" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Upload Rooms</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="roomRequests" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Room's Requests</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="activeRooms" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Active Rooms</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="inactiveRooms" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Inactive Rooms</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="pendingRooms" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Pending Rooms </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="expiredRooms" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Expired Rooms</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Card.Body>
                                    </Card>

                                </Col>
                                <Col md={9}>
                                    <Card className="rightCard">
                                        <Card.Body>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="uploadRooms" >
                                                    <UploadRooms />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="roomRequests" >
                                                    <RoomRequests />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="activeRooms" >
                                                    <ActiveRooms />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="inactiveRooms" >
                                                    <InactiveRooms />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="pendingRooms">
                                                    <PendingRooms />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="expiredRooms">
                                                    <ExpiredRooms />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            </Row>
                        </Tab.Container>
                }

            </Container>
        </>
    )
}


export default SidebarLandlord;