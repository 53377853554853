import { Row, Col, Container, Card, Image } from 'react-bootstrap'
import search from '../search.png'
import request from '../request.png'
import confirmation from '../confirmation.png'
import home from '../home.png'
import '../componentCss/Features.css'
import { useHistory } from "react-router-dom"


export default function Features() {

    const history = useHistory();

    function cardClickHandel() {
        let path = `/rooms`;
        history.push(path);
    }

    return (
        <Container className="features-container" id="howID" fluid>
            <Row>
                <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2 style={{ fontFamily: 'DM Sans' }}>How It Works </h2>
                </Col>
            </Row>
            <Row>
                <Container className="card-container">
                    <Container>
                        <Row>
                            <Col md={3}>
                                <Card id="features_card" onClick={cardClickHandel}>
                                    <Card.Body>
                                        <Image src={search} id="imgicon" />
                                        <div id="ll" style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                            Search Rooms
                                        </div>
                                        <Card.Text style={{ textAlign: 'left', marginLeft: '10px', fontFamily: 'DM Sans' }}>
                                            Search Rooms at any location in your city as per your convenience
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card id="features_card" onClick={cardClickHandel}>
                                    <Card.Body>
                                        <Image src={request} id="imgicon" />
                                        <div id="ll" style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                            Send Request
                                        </div>
                                        <Card.Text style={{ textAlign: 'left', marginLeft: '10px', fontFamily: 'DM Sans' }}>
                                            Select a room and send request to the landlord for that room.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>

                                <Card id="features_card" onClick={cardClickHandel}>
                                    <Image src={confirmation} id="imgicon2" />
                                    <Card.Body>
                                        <div id="ll" style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                            Confirmation
                                        </div>
                                        <Card.Text style={{ textAlign: 'left', marginLeft: '10px', fontFamily: 'DM Sans' }}>
                                            Wait twenty four hours for confirm your request by landlord.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card id="features_card" onClick={cardClickHandel}>
                                    <Image src={home} id="imgicon2" />
                                    <Card.Body>
                                        <div id="ll" style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                            Enjoy your Stay
                                        </div>
                                        <Card.Text style={{ textAlign: 'left', marginLeft: '10px', fontFamily: 'DM Sans' }}>
                                            After confirmation, direct contact to the landlord and enjoy your stay.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </Container>
            </Row>
        </Container>
    )
}