import React from 'react'
import { Container, Row, Col, Card, Tab, Alert } from 'react-bootstrap'
import { FaClock } from "react-icons/fa";

export default function WaitForConfirm() {
    return (
        <div>
            <Container className="landlordDetailsContainer">
                <Tab.Container defaultActiveKey="uploadRooms">
                    <Row>
                        <Col md={12}>
                            <Card className="tenantDetailsCard">
                                <Card.Body>
                                    <Alert variant='primary' style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                        <FaClock style={{fontSize:'80px'}}/>
                                        <p style={{marginTop:'20px'}}>Your profile is <span style={{ color: '#e74c3c'}}>under review !!!</span></p>
                                        <p>Please wait <span style={{ color: '#27ae60' }}>24 hours.</span></p>
                                    </Alert>
                                    
                                </Card.Body>
                            </Card>

                        </Col>

                    </Row>
                </Tab.Container>
            </Container>
        </div>
    )
}
