import React from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col } from 'react-bootstrap'

export default function Terms() {
    return (
        <div>
            <Navigation2 />
            {
                window.scrollTo(0, 0)
            }
            <Container style={{ marginTop: '150px' }}>
                <Row>
                    <Col>
                        <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Terms and Conditions</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            RoomsNearYou is concerned about the usage by its users (hereinafter referred to as TENANTS & LANDLORDS), of its Website located at https://roomsnearyou.in and has provided this Terms and Conditions statement to familiarize the Users with it.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Acceptance of Terms
                        </h1>
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            The services that RoomsNearYou provides to Tenents & Landlords are subject to the following TAC. RoomsNearYou reserves the right to update the TAC at any time without notice to Tenents & Landlords.
                            <br />
                            <br />
                            A. This Agreement, which Softseekrs Infotech Private Limited by reference other provisions applicable to use of Website, including, but not limited to, terms and conditions set forth hereof governing the use of certain specific material contained in Website, sets forth the terms and conditions that apply to use of the Website by User. By using RoomsNearYou Website and Services it offers including upload property, login contacts and Landlords and Tenants (Collectively “Services”), User agrees to comply with all of the terms and conditions hereof. The right to use the Website is personal to User and is not transferable to any other person or entity. User is responsible for all use of User’s Account (under any screen name or contact) and for ensuring that all use of User’s Account complies fully with the provisions of this Agreement. User shall be responsible for protecting the confidentiality of Landlord's & Tenant's personal details.
                            <br />
                            <br />
                            B.RoomsNearYou shall have the right at any time to change or discontinue any aspect or feature of the Website, including, but not limited to, content, hours of availability, and equipment needed for access or use.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Changed Terms
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            RoomsNearYou shall have the right at any time to change or modify the terms and conditions applicable to User’s use of the Website, or any part thereof, or to impose new conditions, including, but not limited to, adding fees and charges for use. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, posting on Website, or by electronic or conventional mail, or by any other means by which User obtains notice thereof. Any use of Website by User after such notice shall be deemed to constitute acceptance by User of such changes, modifications or additions.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Description of Services
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            Through its Website, RoomsNearYou provides User with access to variety of resources, and services such as listing the Landlords’s property for the purpose of rent/lease, posting Landlords’s requirement for a house/property for rent/lease, collecting monthly rent, collecting security deposit etc. The services, including any updates, enhancements, new features, and/or the addition of any new Website, are subject to the TAC.
                            <br />
                            <br />
                            A. User shall use Website for lawful purposes only. User shall not post or transmit through Website any material which violates or infringes in any way upon the rights of others, which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any law. Any conduct by User that in RoomsNearYou discretion restricts or inhibits any other User from using or enjoying the Website will not be permitted.
                            <br />
                            <br />

                            B. Website contains copyrighted material, trademarks and other proprietary information, including, but not limited to text, software, photos, videos, graphics, music and sound, and the entire contents of the Website are copyrighted as a collective work under the Trademark and Copyright laws of India. RoomsNearYou owns a copyright in the selection under Softseekers Infotech Private Limited, coordination, arrangement and enhancement of such content, as well as in the content original to it. User may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part.
                            <br />
                            <br />

                            C. User shall not upload, post or otherwise make available on Website any material protected by copyright, trademark or other proprietary right without the expressive permission of the owner of the copyright, trademark or other proprietary right and the burden of determining that any material is not protected by copyright rests with User. User shall be solely liable for any damage resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from such a submission.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Use of Services
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            The Services may contain email services, personal profile pages, photos, and/or other message or communication facilities designed to enable User to communicate with others (each a “Communication Service” and collectively “Communication Services”). User agrees to use the Communication Services only to post, send and receive messages and material that are proper and when applicable, related to the particular Communication Service. By way of example, and not as a limitation, User agrees that when using the Communication Services, User will not use the communication services for surveys, junk emails, defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others. Commit an act including, but not limited to publishing, posting, uploading inappropriate, profane, obscene photos, videos or any other material or information that is unlawful under the laws of India; Use any material or information that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party; upload files that contain viruses; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Services specifically allows such messages; Download any file posted by another user of a Communication Service that User knows, or reasonably should know, cannot be legally reproduced, displayed, performed, and/or distributed.
                            <br />
                            <br />

                            RoomsNearYou reserves the right to review materials posted to the communication Services and to remove any material in its sole discretion. RoomsNearYou reserves the right to terminate User’s access to any or all of the Communication Services at any time, with notice, for any reason whatsoever. RoomsNearYou reserves the right at all times to disclose any information as it deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in RoomsNearYou’s sole discretion.

                            <br />
                            <br />

                            Users are advised to use caution when giving out any personally identifiable information in any Communication Services. RoomsNearYou does not control or endorse the content, messages or information found in any Communication Services, and therefore, RoomsNearYou specifically disclaims any liability with regard to the Communication Services and any actions resulting from User’s participation in any Communication Services. Managers and hosts are not authorized RoomsNearYou spokespersons, and their views do not necessarily reflect those of RoomsNearYou.

                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Member Account, Password and Security
                        </h1>
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            If any of the Services requires User to open an account, User must complete the otp login process by providing RoomsNearYou with current, complete and accurate information as prompted by the applicable registration form. User is entirely responsible for maintaining the confidentiality of User’s account. Furthermore, User is entirely responsible for any and all activities that occur under User’s account. User agrees to notify RoomsNearYou immediately of any unauthorised use of User’s account or any other breach of security. RoomsNearYou will not be liable for any loss that User may incur as a result of someone else using User’s account, either with or without User’s knowledge .However, User could be held liable for losses incurred by RoomsNearYou or another party due to someone else using User’s account. User may not use anyone else’s account at any time, without the permission of the account holder.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Disclaimer regarding Software, Documents and Services available on this Website
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            In no event shall RoomsNearYou and/or its respective suppliers shall be liable for any special, indirect or consequential damages or any damages whatsoever resulting from loss of use, data or profits, whether in an action of contract, negligence or other tortious action, arising out of or in connection with the use of performance of software, documents, provision of or failure to provide services, or information available from the services.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Details provided to RoomsNearYou or Posted at any of its Websites
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            RoomsNearYou does not claim ownership of the materials User provides to RoomsNearYou (including feedback and suggestions) or post, upload, input or submit to any Services or its associated services for review by the general public, or by the members of any public or private community (each a “Submission” and collectively ‘Submissions’). However, by posting, uploading, inputting, providing or submitting (“Posting”) User’s Submission User is granting RoomsNearYou and its affiliated companies permission to use User’s Submission in connection with the operation of their Internet businesses including, without limitation, the licenses rights to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat User’s Submission; to publish User’s name in connection with User’s Submission; and the right to sublicense such rights to any supplier of the services.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Disclaimer of Warranty; Limitation of Liability
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            A. User expressly agrees that use of the Website is at User’s Sole risk. Neither RoomsNearYou, its affiliates nor any of their respective employees, agents, third party content providers or licensors warrant that the Website will be uninterrupted or error free; Nor do they make any warranty as to the results that may be obtained from the use of the website, or, as to the accuracy, reliability or content of any information, service, or merchandise provided through the website.
                            <br />
                            <br />
                            B. Website is provided on an “As is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this Agreement.
                            <br />
                            <br />
                            C. This disclaimer of liability applies to any damages or injury caused by any failure of performance error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorised access to, alteration of or use of record, whether for breach of contract, tortious behaviour, negligence, or under any other cause of action. User specifically acknowledges that RoomsNearYou is not liable for the defamatory, offensive or illegal conduct of other users or third parties and that the risk of injury from the foregoing rests entirely with the User.
                            <br />
                            <br />
                            D. In addition to the terms set forth above, RoomsNearYou, nor its affiliates, information providers or content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, submission, or other defects in the information contained within the website, or for any other claims or losses arising therefrom or occasioned thereby.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Unsolicited Idea Submission Policy
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            RoomsNearYou or any of its employees do not accept or consider unsolicited ideas, including ideas for new advertising campaigns, new promotions, new products or technologies, processes, materials, marketing plans or new product names. Please do not send any original creative artwork, samples, demos or other works. The sole purpose of this policy is to avoid potential misunderstandings or disputes when RoomsNearYou’s products or marketing strategies might seem similar to ideas submitted to RoomsNearYou. If such unsolicited ideas are sent, despite our request that you should not send us your ideas, please understand that RoomsNearYou makes no assurances that your ideas and materials will be treated as confidential or proprietary.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Monitoring
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            RoomsNearYou shall have the right but not the obligation, to monitor the content of the website, including chat rooms and forums, to determine compliance with this agreement and any operating rules established by RoomsNearYou and to satisfy any law, regulation or authorised government request. RoomsNearYou shall have the right in its sole discretion to edit, refuse to post or remove any material submitted to or posted on Website. Without limiting the foregoing, RoomsNearYou shall have the right to remove any material that RoomsNearYou in its sole discretion, finds to be in violation of the provisions hereof or otherwise objectionable.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Indemnification
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            User agrees to defend, indemnify and hold harmless RoomsNearYou, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorney’s fees, arising out of the use of RoomsNearYou by user or User’s Account.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Termination
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            Either RoomsNearYou or the User may terminate this Agreement at any time. RoomsNearYou shall have the right to immediately terminate the User’s Account in the event of any conduct by User which RoomsNearYou, in its sole discretion, considers to be unacceptable, or in the event of any breach by the User of this Agreement.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Intellectual Property
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            All copyrights and trademarks held by RoomsNearYou with respect to its logos and content posted on the Website by RoomsNearYou etc is the exclusive intellectual property of RoomsNearYou and all rights are reserved on the same.
                        </h1>
                        <br />
                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                            Miscellaneous
                        </h1>

                        <h1 style={{ fontSize: '17px', fontFamily: 'DM Sans' }}>
                            This Agreement and any operating rules for RoomsNearYou established by RoomsNearYou constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede all previous written or oral agreements between the parties with respect to such subject matter. This Agreement shall be construed in accordance with the laws of India.
                        </h1>
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
