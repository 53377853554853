import React, { useState } from 'react'
import { Row, Col, Card, Carousel, Spinner, Modal, Button, Container, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FcApproval } from "react-icons/fc";
import './LandlordCss/RoomRequestsCss.css'
import { QUERY_LANDLORD_BOOKING_REQUEST, ROOM_BY_ID, QUERY_TENANT_BY_ID, MUTATION_CONFIRM_ROOM, MUTATION_DECLINE_ROOM, QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED, QUERY_SEND_SMS_TENANT_REQUEST_CANCELLED } from '../../Queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";
import { SRLWrapper } from "simple-react-lightbox";

export default function RoomRequests() {
    const landlordId = localStorage.getItem('LandlardId');

    const [showRoom, setShowRoom] = useState(false);
    const handleRoomClose = () => setShowRoom(false);
    const handleRoomShow = () => setShowRoom(true);

    const [showTenant, setShowTenant] = useState(false);
    const handleTenantClose = () => setShowTenant(false);
    const handleTenantShow = () => setShowTenant(true);

    const [showConfirm, setConfirmShow] = useState(false);
    const handleConfirmClose = () => setConfirmShow(false);
    const handleConfirmShow = () => setConfirmShow(true);

    const [showCancle, setCancleShow] = useState(false);
    const handleCancleClose = () => setCancleShow(false);
    const handleCancleShow = () => setCancleShow(true);


    const [getRoomByIdLazyQuery, { data: roomByIdData, loading: roomByIdLoading }] = useLazyQuery(ROOM_BY_ID);
    const [getTenantByIdLazyQuery, { data: tenantByIdData, loading: tenantByIdLoading }] = useLazyQuery(QUERY_TENANT_BY_ID);
    const [sendSmsTenantRequestAcceptedLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED);
    const [sendSmsTenantRequestCancelledLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_REQUEST_CANCELLED);

    const [paymentStatusState, setPaymentStatusState] = useState('')

    const [bookingId, setBookingId] = useState('')

    const { data: landlordBookingRequestData, loading: landlordBookingRequestLoading } = useQuery(QUERY_LANDLORD_BOOKING_REQUEST, {
        variables: {
            "landlordId": `${landlordId}`
        }
    });


    const [confirmRoomMutation] = useMutation(MUTATION_CONFIRM_ROOM, {
        refetchQueries: [
            QUERY_LANDLORD_BOOKING_REQUEST,
            'getLandlordBookingRequest'
        ],
    });
    const [declineRoomMutation] = useMutation(MUTATION_DECLINE_ROOM, {
        refetchQueries: [
            QUERY_LANDLORD_BOOKING_REQUEST,
            'getLandlordBookingRequest'
        ],
    });

    function fetchRoomById(rId) {
        handleRoomShow()
        getRoomByIdLazyQuery({
            variables: {
                roomId: `${rId}`
            }
        })
    }

    function fetchTenantById(tId, paymentStatus) {

        setPaymentStatusState(paymentStatus)

        getTenantByIdLazyQuery({
            variables: {
                getTenantById: `${tId}`
            }
        })

        handleTenantShow()
    }



    function confirmRoomHandle(bID, tenantId) {
        setBookingId(bID)
        getTenantByIdLazyQuery({
            variables: {
                getTenantById: `${tenantId}`
            }
        })
        handleConfirmShow()
    }

    function tenantConfirmDone(tenantContact, tenantName) {
        sendSmsTenantRequestAcceptedLazyQuery({
            variables: {
                tenantContact: `${tenantContact}`,
                tenantName: `${tenantName}`,
            }
        })
        confirmRoomMutation({
            variables: {
                bookingId: `${bookingId}`
            }
        })
        handleConfirmClose()
    }




    function declineRoomHandle(bID, tenantId) {
        setBookingId(bID)
        getTenantByIdLazyQuery({
            variables: {
                getTenantById: `${tenantId}`
            }
        })
        handleCancleShow()
    }

    function tenantCancelDone(tenantContact, tenantName) {
        sendSmsTenantRequestCancelledLazyQuery({
            variables: {
                tenantContact: `${tenantContact}`,
                tenantName: `${tenantName}`,
            }
        })
        declineRoomMutation({
            variables: {
                bookingId: `${bookingId}`
            }
        })
        handleCancleClose()
    }

    console.log("Tenant", paymentStatusState)
    return (
        <div>
            <Card>
                <Card.Body>
                    {
                        landlordBookingRequestLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            landlordBookingRequestData && landlordBookingRequestData.getLandlordBookingRequest.map(requestData => {
                                return (
                                    <Row>
                                        <Col md={6}>
                                            <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{requestData.roomType}</p>
                                            <Row>
                                                <Col>
                                                    <Carousel style={{ width: '150px', height: '150px' }}>
                                                        {
                                                            requestData.roomImgKey.map(imageKey => {
                                                                return (
                                                                    <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                        <img
                                                                            className="d-block w-100"
                                                                            src={`https://softbuc.s3.us-east-2.amazonaws.com/${imageKey}`}
                                                                            alt="First slide"
                                                                            style={{ width: '150px', height: '150px' }}
                                                                        />
                                                                    </Carousel.Item>
                                                                )

                                                            })
                                                        }

                                                    </Carousel>
                                                </Col>
                                                <Col>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}> {requestData.roomRent}</span></p>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}> {requestData.roomArea}</span></p>
                                                    <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{requestData.roomUploadDate}</span></p>
                                                    <hr />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={5}><p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}><FcApproval />Tenant is Verified</p></Col>
                                                <Col></Col>
                                                <Col md={3}><p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => fetchTenantById(requestData.tenantId, requestData.payment)}>View Tenant</p></Col>
                                                <Col md={3}><p style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => fetchRoomById(requestData.roomId)}>View Room</p></Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {
                                                        requestData.status === 'confirmed' ?
                                                            <>
                                                                <h3 style={{ fontSize: '15px', fontFamily: 'DM Sans', color: '#2ecc71', fontWeight: 'bold' }}>Tenant Confirm Successfully.</h3>
                                                            </> :
                                                            requestData.status === 'cancelled' ?
                                                                <>
                                                                    <h3 style={{ fontSize: '15px', fontFamily: 'DM Sans', color: '#c0392b', fontWeight: 'bold' }}>Tenant Declined Successfully.</h3>
                                                                </> :
                                                                <>
                                                                    <Button className="confirmBtn" onClick={() => confirmRoomHandle(requestData.id, requestData.tenantId)}>Confirm</Button>
                                                                    <Button className="declineBtn" onClick={() => declineRoomHandle(requestData.id, requestData.tenantId)}>Decline</Button>
                                                                </>

                                                    }

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                    }
                    <hr />
                </Card.Body>
            </Card>


            <Modal show={showRoom} onHide={handleRoomClose}>
                <Modal.Body>
                    {
                        roomByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <SRLWrapper>
                                            <Carousel style={{ width: '100%', height: '200px' }}>
                                                {
                                                    roomByIdData && roomByIdData.getRoomById.imageId.map(activeImages => {
                                                        return (

                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                    alt="First slide"
                                                                    style={{ width: '100%', height: '200px' }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </SRLWrapper>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}>{roomByIdData && roomByIdData.getRoomById.roomType}</p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Rent: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.price}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Area: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.area}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>City: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.city}</span></p> 
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Date: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadDate}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Time: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadTime}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Capacity: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.capacity} Peoples</span></p>
                                    </Col>
                                    <Col style={{ borderLeft: '1px solid rgb(231, 76, 60)' }}>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Bathroom Attatched
                                            {
                                                roomByIdData && roomByIdData.getRoomById.bathroomAttach === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Kitchen Available
                                            {
                                                roomByIdData && roomByIdData.getRoomById.kitchenAvailable === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Water Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.waterBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Electricity Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.electricBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                    </Col>

                                </Row>
                                {
                                    roomByIdData && roomByIdData.getRoomById.remark === "" ? '' :
                                        <Row>
                                            <Col style={{ background: '#bdc3c7', borderRadius: '10px', marginTop: '5px' }}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Remark: <span style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#000' }}>
                                                    {roomByIdData && roomByIdData.getRoomById.remark}
                                                </span></p>
                                            </Col>
                                        </Row>
                                }

                            </Container>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRoomClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showTenant} onHide={handleTenantClose}>
                <Modal.Body>
                    {
                        tenantByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Name:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.fName} {tenantByIdData && tenantByIdData.getTenantById.lName}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Occupation:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.occupation}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Email:</td>
                                                    {
                                                        paymentStatusState === 'false' ?
                                                            <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.email.replace(/(.{2})(.*)(?=@)/, '$1*')}
                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Email is not visible because tenant is not paid Contact Owner Fee yet!!!</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <FaQuestionCircle />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </td> :
                                                            <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.email}</td>
                                                    }

                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Gender:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.gender}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Contact:</td>
                                                    {
                                                        paymentStatusState === 'false' ?
                                                            <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.contact.replace(/.(?=.{4,}$)/g, 'X')}
                                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Contact is not visible because tenant is not paid Contact Owner Fee yet!!!</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <FaQuestionCircle />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </td>:
                                                            <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.contact}</td>
                                                    }

                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>City:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.city}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>State:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>{tenantByIdData && tenantByIdData.getTenantById.state}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}>Verification:</td>
                                                    <td style={{ fontFamily: 'DM Sans' }}>Verified by {tenantByIdData && tenantByIdData.getTenantById.identityType} <FcApproval style={{ fontSize: '13px', marginTop: '-3px' }} /></td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTenantClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showConfirm} onHide={handleConfirmClose} centered>
                <Modal.Body>
                    <Container>
                        {
                            tenantByIdLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                <>
                                    <Row>
                                        <Col>
                                            <h1 style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'DM Sans' }}>Are you sure to confirm this tenant?</h1>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col>
                                            <Button variant="secondary" className="confirmModalYesBtn mx-auto d-block" onClick={() => tenantConfirmDone(tenantByIdData && tenantByIdData.getTenantById.contact, tenantByIdData && tenantByIdData.getTenantById.fName)}>Yes</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="secondary" onClick={handleConfirmClose} className="confirmModalNoBtn mx-auto d-block">No</Button>
                                        </Col>
                                    </Row>
                                </>
                        }
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={showCancle} onHide={handleCancleClose} centered>
                <Modal.Body>
                    <Container>
                        {
                            tenantByIdLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                <>
                                    <Row>
                                        <Col>
                                            <h1 style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'DM Sans' }}>Are you sure to cancel this tenant?</h1>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col>
                                            <Button variant="secondary" className="confirmModalYesBtn mx-auto d-block" onClick={() => tenantCancelDone(tenantByIdData && tenantByIdData.getTenantById.contact, tenantByIdData && tenantByIdData.getTenantById.fName)}>Yes</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="secondary" onClick={handleCancleClose} className="confirmModalNoBtn mx-auto d-block">No</Button>
                                        </Col>
                                    </Row>
                                </>
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
