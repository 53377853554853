import React from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col, Accordion } from 'react-bootstrap'

export default function LandlordFaq() {
    return (
        <div>
            <Navigation2 />
            <Container style={{ marginTop: '150px' }}>
                <Row>
                    <Col>
                        <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Landlord's Frequently Asked Questions (FAQ)</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>Is this free?</Accordion.Header>
                                <Accordion.Body>
                                    <li style={{ fontFamily: 'DM Sans' }}>Yes its totally free for landords (no commission and no brokerage charge).</li>
                                    <li> हाँ यह मकानमालिकों के लिए पूरी तरह से मुफ़्त है (कोई कमीशन नहीं और कोई ब्रोकरेज शुल्क नहीं)।</li>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header style={{ fontFamily: 'DM Sans' }}>How can I upload rooms/property?</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li><strong>English</strong></li>
                                        <ul>
                                            <li>OTP Login as a landlord</li>
                                            <li>Click on Upload Room button</li>
                                            <li>Fill room details and upload rooms pictures.</li>
                                            <li>Click on Upload button.</li>
                                        </ul>

                                        <li><strong>Hindi</strong></li>
                                        <ul>
                                            <li>एक मकान मालिक के रूप में ओटीपी लॉगिन करें|</li>
                                            <li>अपलोड रूम बटन पर क्लिक करें|</li>
                                            <li>कमरे का विवरण भरें और कमरों की तस्वीरें अपलोड करें|</li>
                                            <li>अपलोड बटन पर क्लिक करें|</li>
                                        </ul>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
