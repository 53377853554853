import React from 'react'
import { Button } from 'react-bootstrap'
import Logo from '../../logo.png'

export default function Test() {
    const tenantPay = 50;
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
    });

    const loadScript = (src) => {

        return new Promise((resovle) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resovle(true);
            };
            script.onerror = () => {
                resovle(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async (amount) => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("You are offline... Failed to load Payment Gateway");
            return;
        }
        const options = {
            key: "rzp_test_UTCKPMY4AIjdpk",
            currency: "INR",
            amount: amount * 100,
            name: "RoomsNearYou",
            description: "Payment for get Landlord's Details",
            image: `${Logo}`,

            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert("Payment Successfully");
            },
            prefill: {
                name: "RoomsNearYou",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };




    return (
        <div className="App">
            <div className="product">
                <>
                    <div className="buttons">
                        <Button onClick={() => displayRazorpay(tenantPay)}>
                            Pay {formatter.format(tenantPay)} for get Landlord Details
                        </Button>
                    </div>

                </>
            </div>
        </div>
    )
}
