import React, { useState } from 'react'
import logo from '../logo.png'
import '../componentCss/navigation.css'
import { Container, Row, Col, Navbar, Nav, Button, Modal, Card, Spinner, DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from "react-scroll";
import { Link as Link1, Redirect } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import pricing from '../pricing.png'
import pricing2 from '../pricing2.png'
import { FaExclamationTriangle, FaRegCheckCircle } from "react-icons/fa";
import { useMutation, useLazyQuery } from '@apollo/client'
import { QUERY_SEND_LOGIN_OTP } from '../Queries'
import { gql } from '@apollo/client'
import { FaUserAlt } from "react-icons/fa";

const QUERY_LANDLORD_OTP_LOGIN = gql`
mutation LandlordOtpLoginMutation($landlordOtpLoginContact: String!) {
    landlordOtpLogin(contact: $landlordOtpLoginContact) {
    landlordId
    landlordToken
    landlordTokenExpiration
  }
}
`
const QUERY_TENANT_OTP_LOGIN = gql`
mutation TenantOtpLoginMutation($tenantOtpLoginContact: String!) {
  tenantOtpLogin(contact: $tenantOtpLoginContact) {
    tenantId
    tenantToken
    tenantTokenExpiration
    tenantStatus
  }
}
`

export default function Navigation() {
    const [colorChange, setColorchange] = useState(false);

    const [landlordLoginDone, setLandlordLoginDone] = useState(false)
    const [tenantLoginDone, setTenantLoginDone] = useState(false)

    const [landlordOptSendClick, setLandlordOtpSendClick] = useState(false);

    const [landlordContact, setLandlordContact] = useState('')
    const [landlordOtp, setLandlordOtp] = useState('')

    const [landlordOtpNotMatchedError, setLandlordOtpNotMatchedError] = useState(false)
    const [landlordContactError, setLandlordContactError] = useState(false)
    const [landlordOtpResend, setLandlordOtpResend] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [sendOtpLoginLazyQuery, { loading: sendOtpLoading }] = useLazyQuery(QUERY_SEND_LOGIN_OTP);
    const [landlordLoginMutation, { data: landlordApolloData, loading: landlordApolloLoading }] = useMutation(QUERY_LANDLORD_OTP_LOGIN)
    const [tenantLoginMutation, { data: tenantApolloData, loading: tenantApolloLoading }] = useMutation(QUERY_TENANT_OTP_LOGIN)


    const [tenantOptSendClick, setTenantOtpSendClick] = useState(false);
    const [tenantContact, setTenantContact] = useState('')
    const [tenantOtp, setTenantOtp] = useState('')
    const [tenantOtpNotMatchedError, setTenantOtpNotMatchedError] = useState(false)
    const [tenantContactError, setTenantContactError] = useState(false)
    const [tenantOtpResend, setTenantOtpResend] = useState(false)


    const landlordToken = localStorage.getItem('LandlordToken');
    const tenantToken = localStorage.getItem('TenantToken');

    if (landlordLoginDone) {
        return <Redirect to='/landlordDashboard' />
    }
    if (tenantLoginDone) {
        return <Redirect to='/tenantDashboard' />
    }

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    /* landlord login */

    function generateLandlordOtp() {
        if (landlordContact === '' || landlordContact.length < 10 || landlordContact.length > 10) {
            setLandlordContactError(true)
        }
        else {
            const otp = Math.floor(1000 + Math.random() * 9000);
            setLandlordContactError(false)
            localStorage.removeItem("landlordOtp");

            localStorage.setItem('landlordOtp', otp);
            sendOtpLoginLazyQuery({
                variables: {
                    contact: `${landlordContact}`,
                    otp: `${otp}`
                }
            })
            setLandlordOtpSendClick(true);

        }
    }

    function handleLandlordOnchange(e) {
        setLandlordContact(e.target.value)
    }

    function landlordOtphandle(e) {
        setLandlordOtp(e.target.value)
    }

    async function landlordLogin(landlordContact) {
        if (localStorage.getItem('landlordOtp') === landlordOtp) {
            await landlordLoginMutation({ variables: { landlordOtpLoginContact: landlordContact } })
        }
        else {
            setLandlordOtpNotMatchedError(true)
        }

    }

    function landlordEnterAgainHandle() {
        localStorage.removeItem("landlordOtp")
        setLandlordOtpNotMatchedError(false)
        setLandlordContact('')
        setLandlordOtp('')
        setLandlordOtpSendClick(false)
    }

    function landlordResendOtpHandle() {
        setLandlordOtpResend(true)
        localStorage.removeItem("landlordOtp")
        setLandlordOtp('')
        generateLandlordOtp()
    }




    /* Tenant login */

    function generateTenantOtp() {
        if (tenantContact === '' || tenantContact.length < 10 || tenantContact.length > 10) {
            setTenantContactError(true)
        }
        else {
            const otp = Math.floor(1000 + Math.random() * 9000);
            setTenantContactError(false)
            localStorage.removeItem("tenantOtp");

            localStorage.setItem('tenantOtp', otp);
            sendOtpLoginLazyQuery({
                variables: {
                    contact: `${tenantContact}`,
                    otp: `${otp}`
                }
            })
            setTenantOtpSendClick(true)
        }
    }

    function handleTenantOnchange(e) {
        setTenantContact(e.target.value)
    }

    function tenantOtphandle(e) {
        setTenantOtp(e.target.value)
    }

    async function tenantLogin(tenantContact) {
        if (localStorage.getItem('tenantOtp') === tenantOtp) {
            await tenantLoginMutation({ variables: { tenantOtpLoginContact: tenantContact } })
        }
        else {
            setTenantOtpNotMatchedError(true)
        }

    }

    function tenantEnterAgainHandle() {
        localStorage.removeItem("tenantOtp")
        setTenantOtpNotMatchedError(false)
        setTenantContact('')
        setTenantOtp('')
        setTenantOtpSendClick(false)
    }

    function tenantResendOtpHandle() {
        setTenantOtpResend(true)
        localStorage.removeItem("tenantOtp")
        setTenantOtp('')
        generateTenantOtp()
    }

    if (tenantApolloData) {
        localStorage.setItem('TenantId', tenantApolloData.tenantOtpLogin.tenantId)
        localStorage.setItem('TenantToken', tenantApolloData.tenantOtpLogin.tenantToken)
        localStorage.setItem('TenantTokenExpiration', tenantApolloData.tenantOtpLogin.tenantTokenExpiration)
        localStorage.setItem('TenantStatus', tenantApolloData.tenantOtpLogin.tenantStatus)
        localStorage.removeItem("tenantOtp")
        setTenantLoginDone(true)
    }

    if (landlordApolloData) {
        localStorage.setItem('LandlardId', landlordApolloData.landlordOtpLogin.landlordId)
        localStorage.setItem('LandlordToken', landlordApolloData.landlordOtpLogin.landlordToken)
        localStorage.setItem('LandlordTokenExpiration', landlordApolloData.landlordOtpLogin.landlordTokenExpiration)
        localStorage.removeItem("landlordOtp")
        setLandlordLoginDone(true)
    }



    return (
        <>
            <Navbar collapseOnSelect expand="lg" fixed="top" variant="light" bg="light" className={colorChange ? 'navbar-color2' : 'navbar-color'}>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="80"
                        height="50"
                        className="d-inline-block align-top"
                        alt="logo"
                        style={{ marginLeft: '10px' }}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' style={{ marginRight: '10px' }} onClick={() => setColorchange(true)} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {
                        landlordToken ?
                            <Nav className="ms-auto" style={{ marginRight: '10px', textAlign: 'center' }}>
                                <Link1 to="/" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Home</Link1>
                                <Link1 to="/rooms" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Find Rooms</Link1>
                                <Link to="howID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Features</Link>
                                <Link to="priceID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Pricing</Link>
                                <Link to="aboutID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>About Us</Link>
                                <Link1 to="/contact" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Contact Us</Link1>

                                <DropdownButton align="end" title={<FaUserAlt style={{ color: 'black', fontSize: '15px', marginTop: '-5px' }} />} id="dropdown-menu-align-end" className="btnDrop">
                                    <Dropdown.Item eventKey="1"><Link1 to="/landlordDashboard" style={{ textDecoration: 'none', color: 'rgba(0,0,0,.55)' }}>Dashboard</Link1></Dropdown.Item>
                                    <Dropdown.Item eventKey="2"><Link1 to="/landlordLogout" style={{ textDecoration: 'none', color: 'rgba(0,0,0,.55)' }}>Logout</Link1></Dropdown.Item>
                                </DropdownButton>
                            </Nav> : tenantToken ?
                                <Nav className="ms-auto" style={{ marginRight: '10px', textAlign: 'center' }}>
                                    <Link1 to="/" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Home</Link1>
                                    <Link1 to="/rooms" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Find Rooms</Link1>
                                    <Link to="howID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Features</Link>
                                    <Link to="priceID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Pricing</Link>
                                    <Link to="aboutID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>About Us</Link>
                                    <Link1 to="/contact" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Contact Us</Link1>

                                    <DropdownButton align="end" title={<FaUserAlt style={{ color: 'black', fontSize: '15px', marginTop: '-5px' }} />} id="dropdown-menu-align-end" className="btnDrop">
                                        <Dropdown.Item eventKey="1"><Link1 to="/tenantDashboard" style={{ textDecoration: 'none', color: 'rgba(0,0,0,.55)' }}>Dashboard</Link1></Dropdown.Item>
                                        <Dropdown.Item eventKey="2"><Link1 to="/tenantLogout" style={{ textDecoration: 'none', color: 'rgba(0,0,0,.55)' }}>Logout</Link1></Dropdown.Item>
                                    </DropdownButton>
                                </Nav> :
                                <Nav className="ms-auto" style={{ marginRight: '10px', textAlign: 'center' }}>
                                    <Link1 to="/" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Home</Link1>
                                    <Link1 to="/rooms" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Find Rooms</Link1>
                                    <Link to="howID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Features</Link>
                                    <Link onClick={handleShow} spy={true} offset={-70} duration={500} className="nav-link freeListing" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>List Property <span className="freeTag">Free</span></Link>
                                    <Link to="aboutID" spy={true} offset={-70} duration={500} className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>About Us</Link>
                                    <Link1 to="/contact" className="nav-link" style={{ fontFamily: 'DM Sans', cursor: 'pointer' }}>Contact Us</Link1>
                                    <Button className="login-btn" onClick={handleShow}>Login</Button>
                                </Nav>
                    }

                </Navbar.Collapse>
            </Navbar>
            <Modal show={show} onHide={handleClose} centered className="login-modal" size="lg" >
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col style={{ marginBottom: '10px' }}>
                                <Card className="login-landlord-card-black">
                                    <Fade><Card.Img variant="top" src={pricing} id="login-landlord-card-black-img" className="mx-auto d-block" /></Fade>
                                    <Card.Body>
                                        <Fade>
                                            <Card.Title style={{ fontFamily: 'DM Sans', textAlign: 'center' }}>Landlord's Login</Card.Title>
                                            <Card.Title style={{ fontFamily: 'DM Sans', textAlign: 'center' }}>(मकान मालिक)</Card.Title>
                                        </Fade>
                                        <Fade><Card.Text>
                                            <br />
                                            {
                                                landlordOptSendClick ?
                                                    <>
                                                        <input type="text" placeholder="Enter OTP" className="login-landlord-card-input" value={landlordOtp} onChange={landlordOtphandle} />

                                                        {
                                                            landlordOtpNotMatchedError ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaExclamationTriangle style={{ color: 'red' }} /> Invalid OTP</span>
                                                                </Row> : ''
                                                        }

                                                        {
                                                            landlordOtpResend ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaRegCheckCircle style={{ color: 'green' }} /> OTP Resend Successfully</span>
                                                                </Row> : ''
                                                        }


                                                        <Row>
                                                            <Col>
                                                                <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={() => landlordResendOtpHandle()}>Resend OTP</span>
                                                            </Col>
                                                            <Col>
                                                                <span style={{ fontFamily: 'DM Sans', fontSize: '12px', marginTop: '10px', cursor: 'pointer' }} onClick={() => landlordEnterAgainHandle()}>Enter Again</span>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            landlordApolloLoading ? <Spinner animation="border" className="landlordLoginSpinner mx-auto d-block" /> :
                                                                <Button className="login-landlord-card-send-otp-btn mx-auto d-block" onClick={() => landlordLogin(landlordContact)}>Login</Button>
                                                        }

                                                    </> :
                                                    <>
                                                        <input type="number" placeholder="Enter Your Mobile Number" className="login-landlord-card-input" value={landlordContact} onChange={(e) => handleLandlordOnchange(e)} />
                                                        {
                                                            landlordContactError ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaExclamationTriangle style={{ color: 'red' }} /> Invalid Contact</span>
                                                                </Row> : ''
                                                        }
                                                        {
                                                            sendOtpLoading ? <Spinner animation="border" className="landlordLoginSpinner mx-auto d-block" /> :
                                                                <Button className="login-landlord-card-send-otp-btn mx-auto d-block" onClick={() => generateLandlordOtp()}>Send OTP</Button>
                                                        }


                                                    </>
                                            }
                                        </Card.Text></Fade>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col style={{ marginBottom: '10px' }}>
                                <Card className="login-tenant-card-white">
                                    <Fade><Card.Img variant="top" src={pricing2} id="login-tenant-card-white-img" className="mx-auto d-block" /></Fade>
                                    <Card.Body>
                                        <Fade>
                                            <Card.Title style={{ fontFamily: 'DM Sans', textAlign: 'center' }}>Tenant's Login</Card.Title>
                                            <Card.Title style={{ fontFamily: 'DM Sans', textAlign: 'center' }}>(किरायेदार)</Card.Title>
                                        </Fade>
                                        <Fade><Card.Text>
                                            <br />
                                            {
                                                tenantOptSendClick ?
                                                    <>
                                                        <input type="text" placeholder="Enter OTP" className="login-tenant-card-input" value={tenantOtp} onChange={tenantOtphandle} />

                                                        {
                                                            tenantOtpNotMatchedError ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaExclamationTriangle style={{ color: 'red' }} /> Invalid OTP</span>
                                                                </Row> : ''
                                                        }

                                                        {
                                                            tenantOtpResend ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaRegCheckCircle style={{ color: 'green' }} /> OTP Resend Successfully</span>
                                                                </Row> : ''
                                                        }
                                                        <Row>
                                                            <Col>
                                                                <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }} onClick={() => tenantResendOtpHandle()}>Resend OTP</span>
                                                            </Col>
                                                            <Col>
                                                                <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={() => tenantEnterAgainHandle()}>Enter Again</span>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            tenantApolloLoading ? <Spinner animation="border" className="landlordLoginSpinner mx-auto d-block" /> :
                                                                <Button className="login-tenant-card-send-otp-btn mx-auto d-block" onClick={() => tenantLogin(tenantContact)}>Login</Button>
                                                        }

                                                    </> :
                                                    <>
                                                        <input type="number" placeholder="Enter Your Mobile Number" className="login-tenant-card-input" value={tenantContact} onChange={(e) => handleTenantOnchange(e)} />
                                                        {
                                                            tenantContactError ?
                                                                <Row>
                                                                    <span style={{ fontFamily: 'DM Sans', fontSize: '12px', textAlign: 'center', marginTop: '10px' }}><FaExclamationTriangle style={{ color: 'red' }} /> Invalid Contact</span>
                                                                </Row> : ''
                                                        }
                                                        {
                                                            sendOtpLoading ? <Spinner animation="border" className="landlordLoginSpinner mx-auto d-block" /> :
                                                                <Button className="login-tenant-card-send-otp-btn mx-auto d-block" onClick={() => generateTenantOtp()}>Send OTP</Button>
                                                        }

                                                    </>
                                            }
                                        </Card.Text></Fade>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
            </Modal>
        </>
    )
}

