import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import '../componentCss/Cities.css'
import Sagar from '../sagar.jpg'
import Satna from '../satna.jpg'
import Bhopal from '../bpl.jpg'
import jbp from '../jbp.jpg'
import indore from '../indore.jpg'
import vns from '../vns.jpg'
import { useHistory } from "react-router-dom"

export default function Cities() {
    const history = useHistory();

    function cardClickHandel() {
        let path = `/rooms`;
        history.push(path);
    }

    return (
        <div>
            <Container style={{ marginTop: '30px' }}>
                <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h2 style={{ fontFamily: 'DM Sans' }}>Rooms In Cities</h2>
                    </Col>
                </Row>
            </Container>
            <br/>
            <Container>
                <Row>
                    <Col md={2}>
                        <Image src={Sagar} style={{width:'100%', height:'150px', cursor:'pointer'}} id="cities-img" onClick={cardClickHandel}/>
                        <h3 style={{ fontFamily: 'DM Sans', color:'#343D48', fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px'}}>Sagar</h3>
                    </Col>
                    <Col md={2}>
                    <Image src={jbp} style={{width:'100%', height:'150px'}} id="cities-img" onClick={cardClickHandel}/>
                    <h3 style={{ fontFamily: 'DM Sans', color:'#343D48' , fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px'}}>Jabalpur </h3>
                    </Col>
                    <Col md={2}>
                    <Image src={indore} style={{width:'100%', height:'150px'}} id="cities-img" onClick={cardClickHandel}/>
                    <h3 style={{ fontFamily: 'DM Sans', color:'#343D48' , fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px'}}>Indore </h3>
                    </Col>
                    <Col md={2}>
                        <Image src={Bhopal} style={{width:'100%', height:'150px', cursor:'pointer'}} id="cities-img" onClick={cardClickHandel}/>
                        <h3 style={{ fontFamily: 'DM Sans', color:'#343D48', fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px'}}>Bhopal</h3>
                    </Col>
                    <Col md={2}>
                    <Image src={Satna} style={{width:'100%', height:'150px'}} id="cities-img" onClick={cardClickHandel}/>
                    <h3 style={{ fontFamily: 'DM Sans', color:'#343D48', fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px' }}>Satna </h3>
                    </Col>
                    <Col md={2}>
                    <Image src={vns} style={{width:'100%', height:'150px'}} id="cities-img" onClick={cardClickHandel}/>
                    <h3 style={{ fontFamily: 'DM Sans', color:'#343D48', fontSize:'20px', fontWeight:'bold', textAlign:'center', marginTop:'15px' }}>Varanasi </h3>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
