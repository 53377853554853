import { Nav, Tab, Row, Col, Container, Card, Spinner } from 'react-bootstrap';
import './TenantCss/SidebarTenantCss.css'
import { FcBusinessman } from "react-icons/fc";
import BookedRooms from './BookedRooms';
import ConfirmedRooms from './ConfirmedRooms';
import CancelledRooms from './CancelledRooms';

import { QUERY_TENANT_BY_ID } from '../../Queries'
import { useQuery } from '@apollo/client'


function SidebarTenant() {

    const tenantId = localStorage.getItem('TenantId');

    const { data: tenantByIdData, loading: tenantByIdLoading, error: tenantByIdError } = useQuery(QUERY_TENANT_BY_ID, {
        variables: {
            getTenantById: `${tenantId}`
        }
    });
    if (tenantByIdError) {
        console.log("tenantByIdError", tenantByIdError)
    }
    if (tenantByIdData) {
        // console.log("tenantByIdData", tenantByIdData.getTenantById)
    }


    return (
        <>
            <Container className="sidebarContainer">
                <Tab.Container defaultActiveKey="second">
                    <Row>
                        <Col md={3}>
                            <Card className="leftCard">
                                <Card.Body>
                                    <FcBusinessman className="mx-auto d-block" style={{ fontSize: '4rem' }} />

                                    {
                                        tenantByIdLoading ?
                                            <Spinner animation="border" role="status" className="mx-auto d-block" variant="danger" style={{fontSize:'13px', height:'20px', width:'20px'}}>
                                            </Spinner>
                                            :
                                            <>
                                                <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#303030' }}>{tenantByIdData && tenantByIdData.getTenantById.fName} {tenantByIdData && tenantByIdData.getTenantById.lName}</h4>
                                            </>
                                    }
                                    <hr />
                                    <Nav activeKey="first" >
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="first" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Booked Rooms</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Confirmed Rooms</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Cancelled Rooms </Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col md={9}>
                            <Card className="rightCard">
                                <Card.Body>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" >
                                            <BookedRooms />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second" >
                                            <ConfirmedRooms />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <CancelledRooms />
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}


export default SidebarTenant;