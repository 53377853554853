import React, { useState, useRef } from 'react'
import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap'
import '../componentCss/Footer.css'
import fb from '../fb.png'
import insta from '../insta.png'
import youtube from '../youtube.png'
import link from '../link.png'
import what from '../what.png'
import { CONTACT_FORM_EMAIL_SEND } from '../Queries'
import { useLazyQuery } from '@apollo/client'
import { Link } from "react-scroll";
import { Link as Link1 } from "react-router-dom";
import DigitalIndia from '../Digital_India_logo.svg.png'
import MakeInIndia from '../Make-in-India-Logo.jpg'
import StartUpIndia from '../startup-india.png'

export default function Footer() {

    const [validated, setValidated] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    const [contactFormLazyQuery, { loading: contactFormLoading }] = useLazyQuery(CONTACT_FORM_EMAIL_SEND);

    let nameRef = useRef(null)
    let emailRef = useRef(null)
    let contactRef = useRef(null)
    let subjectRef = useRef(null)
    let messageRef = useRef(null)

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const name = nameRef.current.value;
            const email = emailRef.current.value;
            const contact = contactRef.current.value;
            const subject = subjectRef.current.value;
            const message = messageRef.current.value;

            contactFormLazyQuery({
                variables: {
                    email: `${email}`,
                    name: `${name}`,
                    contact: `${contact}`,
                    subject: `${subject}`,
                    message: `${message}`
                }
            })
            setShowSuccess(true)
        }

        setValidated(true);
    };

    return (
        <div id="contactID">
            <Container fluid className="footer-container">
                <Row>
                    <Col md={1}></Col>
                    <Col style={{ marginTop: '20px' }} md={4}>
                        <h2 style={{ color: '#fff', fontFamily: 'DM Sans', textAlign: 'center' }}>Contact Us</h2>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Full Name" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={nameRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Full Name</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="Enter Email Address" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={emailRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Email</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Contact</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Contact" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={contactRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Contact</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Subject</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Subject" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={subjectRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Subject</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Message</Form.Label>
                                    <Form.Control required as="textarea" placeholder="Enter Message" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={messageRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Message</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            {
                                showSuccess ?
                                    <Row className="mb-3">
                                        <Alert variant='success' style={{ fontSize: '13px', fontFamily: 'DM Sans', textAlign: 'center' }}>
                                            Your form is submitted. We will contact you as soon as possible.
                                        </Alert>
                                    </Row> : ""
                            }

                            {
                                contactFormLoading ?
                                    <Spinner animation="border" role="status" variant="info" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <Button id="footer-btn" className="mx-auto d-block" type="submit">
                                        Submit
                                    </Button>

                            }


                        </Form>
                    </Col>

                    <Col md={1}></Col>

                    <Col style={{ marginTop: '20px' }} md={5} >
                        <h2 style={{ color: '#fff', fontFamily: 'DM Sans', textAlign: 'center' }}>Useful Links</h2>
                        <Row style={{ marginTop: '35px' }}>
                            <Col md={3} style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2' }}>RoomsNearYou</h5>
                                <hr style={{ color: '#fff' }} />
                                <Link to="aboutID" spy={true} offset={-70} duration={500} className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>About Us</Link>
                                <Link1 to="/contact" spy={true} offset={-70} duration={500} className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Contact Us</Link1>
                                <Link1 to="/career" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Career</Link1>
                                <Link1 to="/refund" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Refund Policy</Link1>
                            </Col>
                            <Col md={5} style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2' }}>Tenants</h5>
                                <hr style={{ color: '#fff' }} />
                                <Link1 to="/rooms" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Find Rooms</Link1>
                                <Link1 to="/tenantFaq" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Tenant's FAQ</Link1>
                                <Link1 to="/terms" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Terms and Conditions</Link1>
                            </Col>
                            <Col md={4} style={{ textAlign: 'center' }}>
                                <h5 style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2' }}>Landlords</h5>
                                <hr style={{ color: '#fff' }} />

                                <Link1 to="/landlordFaq" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Landlord's FAQ</Link1>
                                <Link1 to="/privacy" className="nav-link" style={{ color: '#fff', fontFamily: 'DM Sans', lineHeight: '2', cursor: 'pointer', fontSize: '14px' }}>Privacy Policy</Link1>
                            </Col>
                            <Row>
                                <Col md={4}><Image src={StartUpIndia} style={{ width: '120px', height: '100%', marginTop: '5px' }} className="mx-auto d-block" /></Col>
                                <Col md={4}><Image src={DigitalIndia} style={{ width: '120px', height: '50px', marginTop: '10px' }} className="mx-auto d-block" /></Col>
                                <Col md={4}><Image src={MakeInIndia} style={{ width: '120px', height: '50px', marginTop: '10px' }} className="mx-auto d-block" /></Col>
                            </Row>
                        </Row>
                    </Col>
                    <Col md={1}></Col>
                </Row>

                <Row>
                    <hr style={{ color: '#fff', marginTop: '30px', width: '80%' }} className="mx-auto d-block" />
                    <Row>
                        <Col md={1}></Col>
                        <Col md={2}>
                            <h3 style={{ color: '#fff', fontFamily: 'DM Sans' }}>RoomsNearYou</h3>
                        </Col>
                        <Col md={5}>
                            <span style={{ color: 'rgba(255,255,255,0.7)', fontFamily: 'DM Sans', fontSize: '13px', display: 'inline-flex', lineHeight: '3' }}>All right reserved - A product of <a href="https://softseekersinfotech.com/" style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: '5px', color: 'white', fontWeight: 'bold', letterSpacing: '1px' }}> Softseekers Infotech Pvt. Ltd.</a></span>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={3}>
                            <Image src={fb} style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.facebook.com/roomsnearyou", "_blank")} />
                            <Image src={insta} style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => window.open("https://www.instagram.com/roomsnearyou/", "_blank")} />
                            <Image src={youtube} style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => window.open("https://www.youtube.com/channel/UC__vum1M0gwz_hwiSnSXwkA", "_blank")} />
                            <Image src={link} style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => window.open("https://www.linkedin.com/company/75021638/", "_blank")} />
                            <Image src={what} style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => window.open("https://wa.me/+916392519698", "_blank")} />
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
