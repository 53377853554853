import React, { useState, useRef } from 'react'
import Navigation from './Navigation'
import { Container, Row, Col, Image, Form, Button, Alert, Spinner } from 'react-bootstrap'
import { CONTACT_FORM_EMAIL_SEND } from '../Queries'
import { useLazyQuery } from '@apollo/client'
import Footer from './Footer'

export default function Contact() {

    const [validated, setValidated] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    const [contactFormLazyQuery, { loading: contactFormLoading }] = useLazyQuery(CONTACT_FORM_EMAIL_SEND);

    let nameRef = useRef(null)
    let emailRef = useRef(null)
    let contactRef = useRef(null)
    let subjectRef = useRef(null)
    let messageRef = useRef(null)

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();

            const name = nameRef.current.value;
            const email = emailRef.current.value;
            const contact = contactRef.current.value;
            const subject = subjectRef.current.value;
            const message = messageRef.current.value;

            contactFormLazyQuery({
                variables: {
                    email: `${email}`,
                    name: `${name}`,
                    contact: `${contact}`,
                    subject: `${subject}`,
                    message: `${message}`
                }
            }).then(() => {
                setShowSuccess(true)
            })
        }

        setValidated(true);
    };

    return (
        <>
            <Navigation />
            {
                window.scrollTo(0, 0)
            }
            <Container style={{ marginTop: 100 }}>
                <Row>
                    <Col>
                        <h2 style={{ color: '#2c3e50', fontFamily: 'DM Sans', textAlign: 'center', fontWeight: 'bold' }}>Contact Us</h2>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Full Name" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={nameRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Full Name</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="Enter Email Address" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={emailRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Email</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Contact</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Contact" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={contactRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Contact</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Subject</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter Subject" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={subjectRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Subject</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label style={{ color: '#fff', fontFamily: 'DM Sans' }}>Message</Form.Label>
                                    <Form.Control required as="textarea" placeholder="Enter Message" style={{ fontFamily: 'DM Sans', borderRadius: '0px' }} ref={messageRef} />
                                    <Form.Control.Feedback style={{ color: 'green', fontFamily: 'DM Sans' }}>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" style={{ color: 'red', fontFamily: 'DM Sans', fontSize: '12px' }}>Enter Message</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            {
                                showSuccess ?
                                    <Row className="mb-3">
                                        <Alert variant='success' style={{ fontSize: '13px', fontFamily: 'DM Sans', textAlign: 'center' }}>
                                            Your form is submitted. We will contact you as soon as possible.
                                        </Alert>
                                    </Row> : ""
                            }

                            {
                                contactFormLoading ?
                                    <Spinner animation="border" role="status" variant="info" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <Button id="footer-btn" className="mx-auto d-block" type="submit">
                                        Submit
                                    </Button>

                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
