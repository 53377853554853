import React, { useState } from 'react'
import { Row, Col, Card, Carousel, Button, Spinner, Container, Modal } from 'react-bootstrap';
import fire from './fireicon.svg';
import './TenantCss/CancelledRoomsCss.css'
import { FaRegSadTear, FaRegLaughBeam, FaCheckCircle, FaTimesCircle, FaRegFolderOpen } from "react-icons/fa";
import {
    QUERY_CANCELLED_ROOMS,
    ROOM_BY_ID, QUERY_BOOKED_ROOMS,
    MUTATION_BOOK_AGAIN, QUERY_TENANT_BY_ID,
    QUERY_LANDLORD_BY_ID,
    QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST,
    QUERY_SEND_SMS_TENANT_ROOM_REQUEST_DONE
} from '../../Queries'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { SRLWrapper } from "simple-react-lightbox";

export default function CancelledRooms() {

    const tenantId = localStorage.getItem('TenantId');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [confirmShow, setConfirmShow] = useState(false);
    const handleConfirmClose = () => setConfirmShow(false);
    const handleConfirmShow = () => setConfirmShow(true);


    const [bookingId, setBookingId] = useState('')


    const [getRoomByIdLazyQuery, { data: roomByIdData, loading: roomByIdLoading }] = useLazyQuery(ROOM_BY_ID);

    const { data: cancelledRoomData, loading: cancelledRoomLoading, error: cancelledRoomError } = useQuery(QUERY_CANCELLED_ROOMS, {
        variables: {
            tenantId: `${tenantId}`
        }
    });
    if (cancelledRoomError) {
        console.log("tenantByIdError", cancelledRoomError)
    }
    if (cancelledRoomData) {
        //console.log("CancelledData", cancelledRoomData.getCancelledRooms)
    }

    const [tenantByIdLazyQuery, { data: tenantByIdLazyData, loading: tenantByIdLazyLoading }] = useLazyQuery(QUERY_TENANT_BY_ID);
    const [landlordByIdLazyQuery, { data: landlordByIdLazyData, loading: landlordByIdLazyLoading }] = useLazyQuery(QUERY_LANDLORD_BY_ID);

    const [sendSmsLandlordBookingRequestLazyQuery] = useLazyQuery(QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST);
    const [sendSmsTenantBookingDoneByIdLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_ROOM_REQUEST_DONE);


    function fetchRoomById(rId) {
        handleShow()
        getRoomByIdLazyQuery({
            variables: {
                roomId: `${rId}`
            }
        })
    }

    const [bookAgainMutation] = useMutation(MUTATION_BOOK_AGAIN, {
        refetchQueries: [
            QUERY_BOOKED_ROOMS,
            QUERY_CANCELLED_ROOMS,
            'getBookedRooms',
            'getCancelledRooms'

        ],
    });

    function confirmModalShow(bId, tenantId, landlordId) {
        setBookingId(bId)
        tenantByIdLazyQuery({
            variables: {
                getTenantById: `${tenantId}`
            }
        })
        landlordByIdLazyQuery({
            variables: {
                landlordId: `${landlordId}`
            }
        })
        handleConfirmShow()
    }

    function bookAgainHandle(bId) {

        sendSmsLandlordBookingRequestLazyQuery({
            variables: {
                landlordContact: `${landlordByIdLazyData.getLandlordById.contact}`,
                landlordName: `${landlordByIdLazyData.getLandlordById.fName}`,
            }
        })
        sendSmsTenantBookingDoneByIdLazyQuery({
            variables: {
                tenantContact: `${tenantByIdLazyData.getTenantById.contact}`,
                tenantName: `${tenantByIdLazyData.getTenantById.fName}`,
            }
        })

        bookAgainMutation({
            variables: {
                bookingId: `${bId}`
            }
        })
        handleConfirmClose()
    }



    console.log('landlord', landlordByIdLazyData)
    console.log('tenant', tenantByIdLazyData)

    return (
        <div>
            <Card>
                <Card.Body>
                    {
                        cancelledRoomLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block" variant="danger" style={{ fontSize: '13px', height: '20px', width: '20px' }}>
                            </Spinner> :
                            cancelledRoomData && cancelledRoomData.getCancelledRooms.length === 0 ?
                                <Container>
                                    <Row>
                                        <Col>
                                            <FaRegFolderOpen style={{ fontSize: '100px' }} className="mx-auto d-block" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5 style={{ color: '#e74c3c', textAlign: 'center', fontFamily: 'DM Sans', marginTop: '10px', fontSize: '30px' }}>No Cancelled Rooms Found!!!</h5>
                                        </Col>
                                    </Row>
                                </Container>
                                :
                                cancelledRoomData && cancelledRoomData.getCancelledRooms.map(cancelData => {
                                    return (
                                        <Row>
                                            <Col md={6}>
                                                <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{cancelData.roomType}</p>
                                                <Row>
                                                    <Col>
                                                        <Carousel style={{ width: '150px', height: '150px' }}>
                                                            <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src="https://images.pexels.com/photos/4915801/pexels-photo-4915801.jpeg?cs=srgb&dl=pexels-maria-orlova-4915801.jpg&fm=jpg"
                                                                    alt="First slide"
                                                                    style={{ width: '150px', height: '150px' }}
                                                                />
                                                            </Carousel.Item>
                                                        </Carousel>
                                                    </Col>
                                                    <Col>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{cancelData.roomRent}</span></p>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{cancelData.roomArea}</span></p>
                                                        <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{cancelData.roomUploadDate}</span></p>
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={10}><p style={{ color: '#818080', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>
                                                        <img
                                                            src={fire}
                                                            alt="First slide"
                                                            style={{ width: '20px', height: '20px' }} /> <span style={{ color: '#303030', fontFamily: 'DM Sans', fontSize: '15px' }}>50</span> people already view this property, Hurry Up !! </p></Col>
                                                    <Col md={2}><p style={{ color: '#ff7f50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }} onClick={() => fetchRoomById(cancelData.roomId)}>View</p></Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <p style={{ color: '#3742fa', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}><FaRegSadTear /> Your Request is cancelled for this room.</p>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md={7}>  <p style={{ color: '#c0392b', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}><FaRegLaughBeam /> "Don't Worry", You can book again. </p> </Col>
                                                    <Col md={5}>
                                                        <Button className="Rebook-btn" onClick={() => confirmModalShow(cancelData.id, cancelData.tenantId, cancelData.landlordId)}>Book Again</Button>
                                                    </Col>
                                                </Row>


                                            </Col>

                                        </Row>
                                    )
                                })
                    }

                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        roomByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <SRLWrapper>
                                            <Carousel style={{ width: '100%', height: '200px' }}>
                                                {
                                                    roomByIdData && roomByIdData.getRoomById.imageId.map(activeImages => {
                                                        return (

                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                    alt="First slide"
                                                                    style={{ width: '100%', height: '200px' }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </SRLWrapper>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}>{roomByIdData && roomByIdData.getRoomById.roomType}</p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Rent: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.price}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Area: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.area}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Date: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadDate}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Time: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadTime}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Capacity: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.capacity} Peoples</span></p>
                                    </Col>
                                    <Col style={{ borderLeft: '1px solid rgb(231, 76, 60)' }}>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Bathroom Attatched
                                            {
                                                roomByIdData && roomByIdData.getRoomById.bathroomAttach === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Kitchen Available
                                            {
                                                roomByIdData && roomByIdData.getRoomById.kitchenAvailable === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Water Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.waterBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Electricity Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.electricBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    roomByIdData && roomByIdData.getRoomById.remark === "" ? '' :
                                        <Row>
                                            <Col style={{ background: '#bdc3c7', borderRadius: '10px', marginTop: '5px' }}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Remark: <span style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#000' }}>
                                                    {roomByIdData && roomByIdData.getRoomById.remark}
                                                </span></p>
                                            </Col>
                                        </Row>
                                }

                            </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={confirmShow} onHide={handleConfirmClose} centered>
                <Modal.Body>
                    <Container>
                        {
                            tenantByIdLazyLoading ?
                                <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                landlordByIdLazyLoading ?
                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> :
                                    <>
                                        <Row>
                                            <Col>
                                                <h1 style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'DM Sans' }}>Are you sure to Book Again this room?</h1>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col>
                                                <Button variant="secondary" onClick={() => bookAgainHandle(bookingId)} className="confirmModalYesBtn mx-auto d-block">Yes</Button>
                                            </Col>
                                            <Col>
                                                <Button variant="secondary" onClick={handleConfirmClose} className="confirmModalNoBtn mx-auto d-block">No</Button>
                                            </Col>
                                        </Row>
                                    </>
                        }

                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
