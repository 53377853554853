import React, { useState, useRef } from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col, Card, Carousel, Form, Button, Image } from 'react-bootstrap'
import '../componentCss/room.css'
import { QUERY_ALL_ACTIVE_ROOMS, QUERY_SEARCH_ROOM } from '../Queries'
import Skeleton from 'react-loading-skeleton';
import { useQuery, useLazyQuery } from '@apollo/client'
import GetRoomsDetails from './GetRoomsDetails'
import Fade from 'react-reveal/Fade';
import Nodata from '../notFound.png'

import { FaSearch } from "react-icons/fa";

export default function Rooms() {

    const [getDetailsComp, setGetDetailsComp] = useState(false)
    const [singleRoomData, setSingleRoomData] = useState()
    const [validated, setValidated] = useState(false);
    const [searchDone, setSearchDone] = useState(false);
    const [cityState, setCityState] = useState()
    const [locationState, setLocationState] = useState()
    const [roomTypeState, setRoomTypeState] = useState()

    const { data: roomData, loading: roomLoading, error: roomError } = useQuery(QUERY_ALL_ACTIVE_ROOMS);

    const [searchLazyQuery, { data: searchData, loading: searchLoading }] = useLazyQuery(QUERY_SEARCH_ROOM);


    if (roomError) {
        console.log("RoomError", roomError)
    }
    if (roomData) {
        console.log("ROOMS", roomData)
    }
    console.log("ROOMS", roomError)

    let city = useRef(null)
    let roomType = useRef(null)
    let area = useRef(null)

    if (getDetailsComp) {
        return <GetRoomsDetails roomData={singleRoomData} />
    }

    // eslint-disable-next-line array-callback-return
    roomData && roomData.getAllActiveRooms.map(rDataa => {
        if (rDataa.roomType === 'Single Room') {
            // console.log("Filter", rDataa)
        }

    })


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const RoomType = roomType.current.value;
            const Area = area.current.value;
            const City = city.current.value;
            event.preventDefault();
            searchLazyQuery({
                variables: {
                    city: `${City}`,
                    area: `${Area}`,
                    roomType: `${RoomType}`
                }
            })
            setSearchDone(true)

        }

        setValidated(true);
    };
    if (searchData) {
        console.log("searching", searchData)
    }

    let wardArray = []

    roomData && roomData.getAllActiveRooms.map(d => wardArray.push(d.area))

    function removeDuplicates(data) {
        return data.filter((value, index) => data.indexOf(value) === index)
    }

    let dupData = removeDuplicates(wardArray)
    // console.log(dupData)

    // if(searchData.searching){
    //     alert('empty')
    // }

    let d = roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city === 'Sagar, Madhya Pradesh').map(filteredData => {
        return filteredData
    }
    )
    console.log(d)


    return (
        <>
            <Navigation2 />
            {
                window.scrollTo(0, 0)
            }
            <Container style={{ marginTop: '100px' }}>
                {/* <Row>
                    <Col md={12}>
                        <input type='search' results='5' placeholder="Search Location" id="search-input" />
                    </Col>
                </Row> */}
                <Container>
                    <Row>
                        <Col>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="searchBox">
                                    <Form.Group as={Col} md="3" controlId="validationCustom01" className="mx-auto d-block" style={{ marginTop: '8px' }}>
                                        <Form.Control required as="select" className="inputBox" ref={city} onChange={e => setCityState(e.target.value)}>
                                            <option value="All">All</option>
                                            <option value="Sagar, Madhya Pradesh">Sagar, Madhya Pradesh</option>
                                            <option value="Jabalpur, Madhya Pradesh">Jabalpur, Madhya Pradesh</option>
                                            <option value="Indore, Madhya Pradesh">Indore, Madhya Pradesh</option>
                                            <option value="Bhopal, Madhya Pradesh">Bhopal, Madhya Pradesh</option>
                                            {/* <option value="Satna, Madhya Pradesh">Satna, Madhya Pradesh</option>
                                            <option value="Varanasi, Uttar Pradesh">Varanasi, Uttar Pradesh</option> */}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">Please choose city.</Form.Control.Feedback>
                                    </Form.Group>

                                    {/* <Form.Group as={Col} md="3" controlId="validationCustom01" style={{ marginTop: '8px' }}>
                                        <Form.Control required as="select" className="inputBox" ref={area} onChange={e => setLocationState(e.target.value)}>
                                            <option value="" selected="selected" disabled="disabled">Location</option>
                                            {
                                                roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city === cityState).map(filteredData => {
                                                    return (
                                                        <option value={filteredData.area}>{filteredData.area}</option>
                                                    )
                                                }
                                                )

                                            }
                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">Please choose location.</Form.Control.Feedback>
                                    </Form.Group> */}

                                    {/* <Form.Group as={Col} md="3" controlId="validationCustom01" style={{ marginTop: '8px' }}>
                                        <Form.Control required as="select" className="inputBox" ref={roomType} onChange={e => setRoomTypeState(e.target.value)}>
                                            <option value="" selected="selected" disabled="disabled">Type</option>
                                            {

                                                roomData && roomData.getAllActiveRooms.filter(areaData => areaData.area === locationState).map(filteredData => {
                                                    return (
                                                        <option value={filteredData.roomType}>{filteredData.roomType}</option>
                                                    )
                                                }
                                                )
                                            }

                                        </Form.Control>

                                        <Form.Control.Feedback type="invalid">Please choose city.</Form.Control.Feedback>
                                    </Form.Group> */}
{/* 
                                    {
                                        searchDone ?
                                            <>
                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Button type="submit" className="searchBtn mx-auto d-block" style={{ fontFamily: 'DM Sans' }}>Search <FaSearch style={{ marginTop: '-3px', fontSize: '14px' }} /></Button>
                                                </Form.Group>
                                                <Form.Group as={Col} md="1" controlId="validationCustom01">
                                                    <Button type="submit" variant="info" className="searchBtn mx-auto d-block" style={{ fontFamily: 'DM Sans' }} onClick={() => setSearchDone(false)}>All</Button>
                                                </Form.Group>
                                            </>
                                            :
                                            <Form.Group as={Col} md="3" controlId="validationCustom01">
                                                <Button type="submit" className="searchBtn mx-auto d-block">Search <FaSearch style={{ marginTop: '-3px', fontSize: '14px' }} /></Button>
                                            </Form.Group>
                                    } */}

                                </Row>

                            </Form>
                        </Col>
                    </Row>
                </Container>
                {/* <Row>
                    <Col style={{ marginTop: '10px' }}>
                        <h1 style={{ fontFamily: 'DM Sans' }}>Rooms In City</h1>
                    </Col>
                </Row> */}
                {/* <Row style={{ marginTop: '10px' }}>
                    <Col md={2}>
                        <Button className="find-room-btn" style={{ marginTop: '10px' }} onClick={()=>setSingleRoomClick(true)}>Single Rooms</Button>
                    </Col>
                    <Col md={2}>
                        <Button className="find-room-btn" style={{ marginTop: '10px' }}>Flats</Button>
                    </Col>
                    <Col md={2}>
                        <Button className="find-room-btn" style={{ marginTop: '10px' }}>Hostels</Button>
                    </Col>
                    <Col md={2}>
                        <Button className="find-room-btn" style={{ marginTop: '10px' }}>All</Button>
                    </Col>
                </Row> */}

                <Container style={{ marginTop: '40px' }}>
                    <Row>
                        {
                            roomLoading ?
                                <>
                                    <Col md={3}>
                                        <Card className="find-room-card">
                                            <Skeleton height={150} />
                                            <Card.Body>
                                                <Skeleton width={200} />
                                                <Card.Text>
                                                    <Skeleton />
                                                    <Row>
                                                        <Col><Skeleton width={90} /></Col>
                                                        <Col>
                                                            <Skeleton height={30} width={90} />
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card className="find-room-card">
                                            <Skeleton height={150} />
                                            <Card.Body>
                                                <Skeleton width={200} />
                                                <Card.Text>
                                                    <Skeleton />
                                                    <Row>
                                                        <Col><Skeleton width={90} /></Col>
                                                        <Col>
                                                            <Skeleton height={30} width={90} />
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card className="find-room-card">
                                            <Skeleton height={150} />
                                            <Card.Body>
                                                <Skeleton width={200} />
                                                <Card.Text>
                                                    <Skeleton />
                                                    <Row>
                                                        <Col><Skeleton width={90} /></Col>
                                                        <Col>
                                                            <Skeleton height={30} width={90} />
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card className="find-room-card">
                                            <Skeleton height={150} />
                                            <Card.Body>
                                                <Skeleton width={200} />
                                                <Card.Text>
                                                    <Skeleton />
                                                    <Row>
                                                        <Col><Skeleton width={90} /></Col>
                                                        <Col>
                                                            <Skeleton height={30} width={90} />
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </>
                                :
                                cityState === undefined ?
                                    roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city).slice().reverse().map(filteredData => {
                                        return (
                                            <Col md={3}>
                                                <Fade><Card className="find-room-card">
                                                    <Carousel style={{ width: '100%', height: '200px' }} interval={null}>
                                                        {
                                                            filteredData && filteredData.imageId.map(activeImages => {
                                                                return (

                                                                    <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                        <img
                                                                            className="d-block w-100"
                                                                            src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                            alt="First slide"
                                                                            style={{ width: '100%', height: '200px' }}
                                                                        />
                                                                    </Carousel.Item>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                    <Card.Body onClick={() => {
                                                        setSingleRoomData(filteredData)
                                                        setGetDetailsComp(true)
                                                    }
                                                    } style={{ cursor: 'pointer' }}>
                                                        <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                                            <Row>
                                                                <Col md={7}>{filteredData.roomType}</Col>
                                                                <Col md={5} style={{ fontSize: '15px' }}>₹{filteredData.price} /-</Col>
                                                            </Row>

                                                        </Card.Title>

                                                        <Card.Text>
                                                            <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {filteredData.area}</span>
                                                            <Row>
                                                                <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{filteredData.city}</Col>
                                                                {/* <Col>
                                                            <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                                        </Col> */}
                                                            </Row>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card></Fade>
                                            </Col>
                                        )
                                    }
                                    ) :
                                    cityState === 'All' ?
                                    roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city).slice().reverse().map(filteredData => {
                                        return (
                                            <Col md={3}>
                                                <Fade><Card className="find-room-card">
                                                    <Carousel style={{ width: '100%', height: '200px' }} interval={null}>
                                                        {
                                                            filteredData && filteredData.imageId.map(activeImages => {
                                                                return (

                                                                    <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                        <img
                                                                            className="d-block w-100"
                                                                            src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                            alt="First slide"
                                                                            style={{ width: '100%', height: '200px' }}
                                                                        />
                                                                    </Carousel.Item>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                    <Card.Body onClick={() => {
                                                        setSingleRoomData(filteredData)
                                                        setGetDetailsComp(true)
                                                    }
                                                    } style={{ cursor: 'pointer' }}>
                                                        <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                                            <Row>
                                                                <Col md={7}>{filteredData.roomType}</Col>
                                                                <Col md={5} style={{ fontSize: '15px' }}>₹{filteredData.price} /-</Col>
                                                            </Row>

                                                        </Card.Title>

                                                        <Card.Text>
                                                            <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {filteredData.area}</span>
                                                            <Row>
                                                                <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{filteredData.city}</Col>
                                                                {/* <Col>
                                                            <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                                        </Col> */}
                                                            </Row>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card></Fade>
                                            </Col>
                                        )
                                    }
                                    ):
                                    locationState === undefined && roomTypeState === undefined ?
                                        roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city === cityState).slice().reverse().map(filteredData => {
                                            return (
                                                filteredData ?
                                                    <Col md={3}>
                                                        <Fade><Card className="find-room-card">
                                                            <Carousel style={{ width: '100%', height: '200px' }} interval={null}>
                                                                {
                                                                    filteredData && filteredData.imageId.map(activeImages => {
                                                                        return (

                                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                                <img
                                                                                    className="d-block w-100"
                                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                                    alt="First slide"
                                                                                    style={{ width: '100%', height: '200px' }}
                                                                                />
                                                                            </Carousel.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Carousel>
                                                            <Card.Body onClick={() => {
                                                                setSingleRoomData(filteredData)
                                                                setGetDetailsComp(true)
                                                            }
                                                            } style={{ cursor: 'pointer' }}>
                                                                <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                                                    <Row>
                                                                        <Col md={7}>{filteredData.roomType}</Col>
                                                                        <Col md={5} style={{ fontSize: '15px' }}>₹{filteredData.price} /-</Col>
                                                                    </Row>

                                                                </Card.Title>

                                                                <Card.Text>
                                                                    <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {filteredData.area}</span>
                                                                    <Row>
                                                                        <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{filteredData.city}</Col>
                                                                        {/* <Col>
                                                            <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                                        </Col> */}
                                                                    </Row>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card></Fade>
                                                    </Col> :
                                                    <Container>
                                                        <Row>
                                                            <Col>
                                                                <Image src={Nodata} style={{ width: '300px' }} className="mx-auto d-block" />
                                                                <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>No Property Available Right Now</h1>
                                                                <br />
                                                                <h1 style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'DM Sans' }}>We are in the process of adding more and more properies for you. Please visit again.</h1>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                            )
                                        }
                                        )
                                        :
                                        roomTypeState === undefined ?
                                            roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city === cityState && areaData.area === locationState).slice().reverse().map(filteredData => {
                                                return (
                                                    filteredData ?
                                                        <Col md={3}>
                                                            <Fade><Card className="find-room-card">
                                                                <Carousel style={{ width: '100%', height: '200px' }} interval={null}>
                                                                    {
                                                                        filteredData && filteredData.imageId.map(activeImages => {
                                                                            return (

                                                                                <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                                    <img
                                                                                        className="d-block w-100"
                                                                                        src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                                        alt="First slide"
                                                                                        style={{ width: '100%', height: '200px' }}
                                                                                    />
                                                                                </Carousel.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </Carousel>
                                                                <Card.Body onClick={() => {
                                                                    setSingleRoomData(filteredData)
                                                                    setGetDetailsComp(true)
                                                                }
                                                                } style={{ cursor: 'pointer' }}>
                                                                    <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                                                        <Row>
                                                                            <Col md={7}>{filteredData.roomType}</Col>
                                                                            <Col md={5} style={{ fontSize: '15px' }}>₹{filteredData.price} /-</Col>
                                                                        </Row>

                                                                    </Card.Title>

                                                                    <Card.Text>
                                                                        <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {filteredData.area}</span>
                                                                        <Row>
                                                                            <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{filteredData.city}</Col>
                                                                            {/* <Col>
                                                            <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                                        </Col> */}
                                                                        </Row>
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card></Fade>
                                                        </Col> :
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <Image src={Nodata} style={{ width: '300px' }} className="mx-auto d-block" />
                                                                    <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>No Property Available Right Now</h1>
                                                                    <br />
                                                                    <h1 style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'DM Sans' }}>We are in the process of adding more and more properies for you. Please visit again.</h1>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                )
                                            }
                                            ) :
                                            roomData && roomData.getAllActiveRooms.filter(areaData => areaData.city === cityState && areaData.area === locationState && areaData.roomType === roomTypeState).slice().reverse().map(filteredData => {
                                                return (
                                                    filteredData ?
                                                        <Col md={3}>
                                                            <Fade><Card className="find-room-card">
                                                                <Carousel style={{ width: '100%', height: '200px' }} interval={null}>
                                                                    {
                                                                        filteredData && filteredData.imageId.map(activeImages => {
                                                                            return (

                                                                                <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                                    <img
                                                                                        className="d-block w-100"
                                                                                        src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                                        alt="First slide"
                                                                                        style={{ width: '100%', height: '200px' }}
                                                                                    />
                                                                                </Carousel.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </Carousel>
                                                                <Card.Body onClick={() => {
                                                                    setSingleRoomData(filteredData)
                                                                    setGetDetailsComp(true)
                                                                }
                                                                } style={{ cursor: 'pointer' }}>
                                                                    <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                                                        <Row>
                                                                            <Col md={7}>{filteredData.roomType}</Col>
                                                                            <Col md={5} style={{ fontSize: '15px' }}>₹{filteredData.price} /-</Col>
                                                                        </Row>

                                                                    </Card.Title>

                                                                    <Card.Text>
                                                                        <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {filteredData.area}</span>
                                                                        <Row>
                                                                            <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{filteredData.city}</Col>
                                                                            {/* <Col>
                                                            <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                                        </Col> */}
                                                                        </Row>
                                                                    </Card.Text>
                                                                </Card.Body>
                                                            </Card></Fade>
                                                        </Col> :
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <Image src={Nodata} style={{ width: '300px' }} className="mx-auto d-block" />
                                                                    <h1 style={{ textAlign: 'center', fontSize: '30px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>No Property Available Right Now</h1>
                                                                    <br />
                                                                    <h1 style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'DM Sans' }}>We are in the process of adding more and more properies for you. Please visit again.</h1>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                )
                                            }
                                            )
                        }
                    </Row>
                </Container>
            </Container>
            <br/>
            <br/>
        </>
    )
}


// {roomData && roomData.getAllActiveRooms.filter(person => person.area.includes('S') && person.area === 'Saket Nagar').map(filteredPerson => (
//     <li>
//         {filteredPerson.area}
//     </li>
// ))}


// dupData.map(same =>
//     <option value={same}>{same}</option>
// )







// roomData && roomData.getAllActiveRooms.slice().reverse().map((rData) =>
                                    // <Col md={3}>
                                    //     <Fade><Card className="find-room-card">
                                    //         <Carousel style={{ width: '100%', height: '200px' }}>
                                    //             {
                                    //                 rData && rData.imageId.map(activeImages => {
                                    //                     return (

                                    //                         <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                    //                             <img
                                    //                                 className="d-block w-100"
                                    //                                 src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                    //                                 alt="First slide"
                                    //                                 style={{ width: '100%', height: '200px' }}
                                    //                             />
                                    //                         </Carousel.Item>
                                    //                     )
                                    //                 })
                                    //             }
                                    //         </Carousel>
                                    //         <Card.Body onClick={() => {
                                    //             setSingleRoomData(rData)
                                    //             setGetDetailsComp(true)
                                    //         }
                                    //         } style={{ cursor: 'pointer' }}>
                                    //             <Card.Title style={{ fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                    //                 <Row>
                                    //                     <Col md={7}>{rData.roomType}</Col>
                                    //                     <Col md={5} style={{ fontSize: '15px' }}>₹{rData.price} /-</Col>
                                    //                 </Row>

                                    //             </Card.Title>

                                    //             <Card.Text>
                                    //                 <span style={{ fontFamily: 'Dm Sans', color: '#95a5a6' }}>Area: {rData.area}</span>
                                    //                 <Row>
                                    //                     <Col style={{ fontFamily: 'Dm Sans', fontWeight: 'bold', fontSize: '15px' }}>{rData.city}</Col>
                                    //                     {/* <Col>
                                    //                         <Button className="find-room-book-btn" onClick={()=>alert('contact owner')}>Contact Owner</Button>
                                    //                     </Col> */}
                                    //                 </Row>
                                    //             </Card.Text>
                                    //         </Card.Body>
                                    //     </Card></Fade>
                                    // </Col>
//                                 )