import React, { useState } from 'react'
import Navigation2 from './Navigation2'
import { Container, Row, Col, Card, Carousel, Image, Button, Table, Alert, Modal, Spinner } from 'react-bootstrap'
import { SRLWrapper } from "simple-react-lightbox";
import { FaHome, FaMoneyBillAlt, FaBed, FaClock, FaRegCalendar, FaLocationArrow, FaCheckCircle, FaTimesCircle, FaRegThumbsUp } from "react-icons/fa"
import Rooms from './Rooms';
import Fade from 'react-reveal/Fade';
import { MUTATION_CREATE_BOOKING, QUERY_BOOKED_ROOMS, QUERY_CHECK_TENANT_REQUEST, QUERY_TENANT_BY_ID, QUERY_LANDLORD_BY_ID, QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST, QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED } from '../Queries'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import Party from '../party.png'
import {Link} from 'react-router-dom'
import '../componentCss/getRoomsDetails.css'

export default function GetRoomsDetails(props) {
    const roomData = props.roomData
    const tenantId = localStorage.getItem('TenantId');

    const tenantToken = localStorage.getItem('TenantToken');
    //const tenantStatus = localStorage.getItem('TenantStatus');

    

    const landlordToken = localStorage.getItem('LandlordToken');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showSucessfull, setShowSucessfull] = useState(false);
    const handleSucessfullClose = () => setShowSucessfull(false);
    const handleSucessfullShow = () => setShowSucessfull(true);

    const [goBack, setGoBack] = useState(false);

    const [tenantLoginNotFound, setTenantLoginNotFound] = useState(false)
    const [tenantStatusTemp, setTenantStatusTemp] = useState(false)
    const [tenantStatusPending, setTenantStatusPending] = useState(false)
    const [landlordLoginOccur, setLandlordLoginOccur] = useState(false)

    const [createBookingMutation] = useMutation(MUTATION_CREATE_BOOKING, {
        refetchQueries: [
            QUERY_BOOKED_ROOMS,
            'getBookedRooms',
            QUERY_CHECK_TENANT_REQUEST,
            'teanantAlreadyBookingCheck',
        ],
    });

    const { data: checkTenantRequestData, loading: checkTenantRequestLoading } = useQuery(QUERY_CHECK_TENANT_REQUEST, {
        variables: {
            tenantsId: `${tenantId}`,
            roomsId: `${roomData.id}`
        }
    });

    const { data: tenantByIdData, loading: tenantByIdLoading } = useQuery(QUERY_TENANT_BY_ID, {
        variables: {
            getTenantById: `${tenantId}`,
        }
    });

    const { data: landlordByIdData, loading: landlordByIdLoading } = useQuery(QUERY_LANDLORD_BY_ID, {
        variables: {
            landlordId: `${props.roomData.landlordId}`
        }
    });

    const [sendSmsLandlordBookingRequestLazyQuery] = useLazyQuery(QUERY_SEND_SMS_LANDLORD_BOOKING_REQUEST);
    const [sendSmsTenantRequestAcceptedLazyQuery] = useLazyQuery(QUERY_SEND_SMS_TENANT_REQUEST_ACCEPTED);




    function contactOwnerHandle() {
        handleClose()
        if (landlordToken) {
            setLandlordLoginOccur(true)
        }
        else if (!tenantToken) {
            setTenantLoginNotFound(true)
        }
        else if (tenantByIdData && tenantByIdData.getTenantById.status === 'tenantTemp') {
            setTenantStatusTemp(true)
        }
        else if (tenantByIdData && tenantByIdData.getTenantById.status === 'pending') {
            setTenantStatusPending(true)
        }
        else if (tenantByIdData && tenantByIdData.getTenantById.status === 'active') {

            let today = new Date();
            let dd = today.getDate();

            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();

            const DateNow = dd + '/' + mm + '/' + yyyy;


            let time = new Date();
            const timeNow = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            createBookingMutation({
                variables: {
                    bookingInput: {
                        landlordId: `${roomData.landlordId}`,
                        roomId: `${roomData.id}`,
                        tenantId: `${tenantId}`,
                        bookingDate: `${DateNow}`,
                        bookingTime: `${timeNow}`,
                        momentTime: `${today}`,
                        status: "confirmed",
                        roomImgKey: roomData.imageId,
                        roomType: `${roomData.roomType}`,
                        roomRent: `${roomData.price}`,
                        roomArea: `${roomData.area}`,
                        roomAddress: `${roomData.roomAddress}`,
                        roomUploadDate: `${roomData.uploadDate}`,
                        payment: "true",
                    },
                }
            })

            sendSmsLandlordBookingRequestLazyQuery({
                variables: {
                    landlordContact: `${landlordByIdData.getLandlordById.contact}`,
                    landlordName: `${landlordByIdData.getLandlordById.fName}`,
                }
            })

            sendSmsTenantRequestAcceptedLazyQuery({
                variables: {
                    tenantContact: `${tenantByIdData.getTenantById.contact}`,
                    tenantName: `${tenantByIdData.getTenantById.fName}`,
                }
            })


            handleSucessfullShow();



        }
        else {
            alert('Somthing went wrong!!!')
        }

    }


    if (goBack) {
        return <Rooms />
    }



    return (
        <div>
            <Navigation2 />
            <Fade top><Container>
                <Row>
                    <Col>
                        <Card style={{ marginTop: '130px' }}>
                            {
                                landlordLoginOccur ?
                                    <Alert variant="danger" style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>You are login as Landlord. For Contact Owner you have to login in with Tenant's Login</span>
                                    </Alert> :
                                    tenantLoginNotFound ?
                                        <Alert variant="danger" style={{ textAlign: 'center' }}>
                                            <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>You have to logged in with Tenant's Login for Contact Owner</span>
                                        </Alert> :

                                        tenantStatusTemp ?
                                            <Alert variant="danger" style={{ textAlign: 'center' }}>
                                                <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Your profile is not completed yet. Please complete your profile first for Contact Owner.</span>
                                            </Alert> :

                                            tenantStatusPending ?
                                                <Alert variant="danger" style={{ textAlign: 'center' }}>
                                                    <span style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold' }}>Sorry!!! Your Profile is under review / not activated.</span>
                                                </Alert> :
                                                ''


                            }
                            <Card.Body>
                                <SRLWrapper>
                                    <Container>
                                        <Row>
                                            <Col md={5}>
                                                <Carousel style={{ width: '100%', height: '300px' }}>
                                                    {
                                                        roomData.imageId.map(imageData => {
                                                            return (
                                                                <Carousel.Item>
                                                                    <Image
                                                                        className="d-block w-100"
                                                                        src={`https://softbuc.s3.us-east-2.amazonaws.com/${imageData}`}
                                                                        alt="First slide"
                                                                        style={{ height: '300px' }}
                                                                    />
                                                                </Carousel.Item>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </Col>
                                            <Col md={7}>
                                                <Row>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}><FaHome style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.roomType}</Col>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans' }}><FaMoneyBillAlt style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.price}</Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans' }}><FaRegCalendar style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.uploadDate}</Col>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans' }}><FaClock style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.uploadTime}</Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans' }}><FaLocationArrow style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.area}</Col>
                                                    <Col md={6} style={{ fontSize: '15px', fontFamily: 'DM Sans' }}><FaBed style={{ marginTop: '-3px', fontSize: '16px' }} /> {roomData.capacity} People</Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col md={6}>
                                                        <Table>
                                                            <tbody>
                                                                <tr style={{ fontSize: '13px', fontFamily: 'DM Sans' }}>
                                                                    <td>Bathroom Attatched</td>
                                                                    <td>
                                                                        {
                                                                            roomData.bathroomAttach === "yes" ? <FaCheckCircle style={{ color: 'green' }} /> :
                                                                                <FaTimesCircle style={{ color: 'red' }} />
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                <tr style={{ fontSize: '13px', fontFamily: 'DM Sans' }}>
                                                                    <td>Kitchen Available</td>
                                                                    <td>
                                                                        {
                                                                            roomData.kitchenAvailable === "yes" ? <FaCheckCircle style={{ color: 'green' }} /> :
                                                                                <FaTimesCircle style={{ color: 'red' }} />
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                <tr style={{ fontSize: '13px', fontFamily: 'DM Sans' }}>
                                                                    <td>Water Price Included</td>
                                                                    <td>
                                                                        {
                                                                            roomData.waterBillInclude === "yes" ? <FaCheckCircle style={{ color: 'green' }} /> :
                                                                                <FaTimesCircle style={{ color: 'red' }} />
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                <tr style={{ fontSize: '13px', fontFamily: 'DM Sans' }}>
                                                                    <td>Eletricity Price Included</td>
                                                                    <td>
                                                                        {
                                                                            roomData.electricBillInclude === "yes" ? <FaCheckCircle style={{ color: 'green' }} /> :
                                                                                <FaTimesCircle style={{ color: 'red' }} />
                                                                        }

                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    {
                                                                        tenantByIdLoading ?
                                                                        <Spinner animation="border" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </Spinner> :
                                                                        checkTenantRequestLoading ?
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </Spinner> :

                                                                            checkTenantRequestData && checkTenantRequestData.teanantAlreadyBookingCheck === null ?
                                                                                <td><Button variant="warning" size="sm" style={{ borderRadius: '0px', fontFamily: 'DM Sans', fontWeight: 'bold' }} onClick={() => handleShow()}>Contact Owner <FaRegThumbsUp style={{ marginTop: '-5px' }} /></Button></td> :

                                                                                checkTenantRequestData && checkTenantRequestData.teanantAlreadyBookingCheck.status === "pending" ?
                                                                                    <>
                                                                                        <h3 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#e74c3c', fontWeight: 'bold' }}>You have already send request to landlord for this room.</h3>
                                                                                        <h5 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#9b59b6', marginTop: '-15px' }}><span style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#2c3e50', marginTop: '-15px' }}>For Landlord's Contact Details please visit <strong>Confirmed Rooms</strong> in your dashboard/profile </span></h5>
                                                                                    </>
                                                                                    :

                                                                                    checkTenantRequestData && checkTenantRequestData.teanantAlreadyBookingCheck.status === "confirmed" ?
                                                                                        <>
                                                                                            <h3 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#27ae60', fontWeight: 'bold' }}>Your request is already confirmed by landlord.</h3>
                                                                                            <h5 style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#9b59b6', marginTop: '-15px' }}><span style={{ fontSize: '12px', fontFamily: 'DM Sans', color: '#2c3e50', marginTop: '-15px' }}>For Landlord's Contact Details please visit <strong>Confirmed Rooms</strong> in your dashboard/profile </span></h5>
                                                                                            <Link to="/tenantDashboard" className="dashboardBtn">Dashboard</Link>
                                                                                        </>
                                                                                        : ''


                                                                    }

                                                                    <td><Button variant="danger" size="sm" style={{ borderRadius: '0px', fontFamily: 'DM Sans' }} onClick={() => setGoBack(true)}>Back</Button></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                    <Col md={6}>
                                                        {
                                                            roomData.remark === "" ? null :
                                                                <>
                                                                    <div style={{ background: '#ecf0f1', width: '100%', height: '100%', borderRadius: '10px' }}>
                                                                        <strong style={{ marginLeft: '10px', fontSize: '14px', fontFamily: 'DM Sans' }}>Remark:</strong>
                                                                        <br />
                                                                        <small style={{ marginLeft: '10px', marginRight: '10px', fontFamily: 'DM Sans' }}>{roomData.remark}</small>
                                                                    </div>
                                                                </>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>

                                                </Row>
                                            </Col>
                                        </Row>


                                    </Container>
                                </SRLWrapper>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container></Fade>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <h1 style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'DM Sans' }}>Are you sure to Contact Owner for this room?</h1>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                {
                                    tenantByIdLoading ?
                                        <>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </> :
                                        landlordByIdLoading ?
                                            <>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </> :
                                            <Button variant="secondary" onClick={contactOwnerHandle} className="confirmModalYesBtn mx-auto d-block">Yes</Button>

                                }

                            </Col>
                            <Col>
                                <Button variant="secondary" onClick={handleClose} className="confirmModalNoBtn mx-auto d-block">No</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showSucessfull} onHide={handleSucessfullClose} centered>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Image src={Party} className="mx-auto d-block" style={{ width: '80px' }} />
                                <h1 style={{ textAlign: 'center', fontSize: '25px', fontFamily: 'DM Sans', color: '#9b59b6' }}>Congratulation</h1>
                                <p style={{ textAlign: 'center', fontSize: '15px', fontFamily: 'DM Sans', color: '#2980b9' }}>Your request for contact owner is accepted. Please visit <strong>Confirmed Rooms</strong> in your dashboard/profile.</p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <Button variant="secondary" onClick={handleSucessfullClose} style={{ backgroundColor: '#2ecc71' }} className="confirmModalNoBtn mx-auto d-block">OK</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}
