import React from 'react'
import SidebarLandlord from './SidebarLandlord'
import Navigation2 from '../Navigation2'
import { Redirect } from 'react-router-dom'
import { QUERY_LANDLORD_BY_ID } from '../../Queries'
import { useQuery } from '@apollo/client'
import LandlordDetails from './LandlordDetails'
// import { Offline, Online } from "react-detect-offline"

export default function LandlordDashboard() {
    const landlordId = localStorage.getItem('LandlardId');
    const landlordToken = localStorage.getItem('LandlordToken');
    const { data: landlordByIdData, error: landlordByIdError } = useQuery(QUERY_LANDLORD_BY_ID, {
        variables: {
            landlordId: `${landlordId}`
        }
    });
    if (landlordByIdError) {
        console.log("landlordByIdError", landlordByIdError)
    }

    if (!landlordToken) {
        return <Redirect to="/" />
    }

    return (
        <div>
            {
                window.scrollTo(0, 0)
            }
            <Navigation2 />
            {/* <div>
            <Offline>You're offline right now. Check your connection.</Offline>
            <Online>You're online right now.</Online>
            </div> */}
            {
                landlordByIdData && landlordByIdData.getLandlordById.status === 'landlordTemp' ? <LandlordDetails /> :
                    <SidebarLandlord />
            }
        </div>
    )
}
