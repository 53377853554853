import React from 'react'
import Navigation from './Navigation';
import Header from './Header';
import Features from './Features';
import About from './About';
// import RecentRooms from './RecentRooms';
import Pricing from './Pricing';
import Cities from './Cities';
import Footer from './Footer';
import { Helmet } from "react-helmet";

export default function Home() {
    return (
        <div>

            <Navigation />
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="RoomsNearYou | Online Rooms Renting Website" />
                <meta name="description" content="Find the best rooms near you with roomsnearyou.in! Find the perfect sublets, rooms, apartments, homes, vacation rentals, and more. Rooms near you is a website which allows you to search for Room Rentals in your area. This website has a very huge database of rooms available for rent. At the moment, we have rooms available in cities like Sagar, Jabalpur, Rewasa, Katni, Satna, Bhopal. Rooms near you helps you to find and book rooms online for your short stays and short term needs. Check out and book rooms and hotels near you and get an amazing experience. Rooms Near You is a free service for finding nearby rooms for rent. Whether you're looking for a 1 bedroom apartment, a studio, or 1 room for rent, we will help you find the best rooms near you. Rooms near you is a platform for students and young professionals to find their rooms and flats with ease." />
                <meta name="keywords" content="oyo rooms in sagar,
                            single room for rent in sagar,
                            hotels in sagar madhya pradesh,
                            hotels in sagar for unmarried couples,
                            hotel in sagar m p near railway station,
                            5 star hotels in sagar mp,
                            olx house for rent in gopalganj sagar mp,
                            room for rent in gopalganj sagar,
                            rooms in sagar karnataka,
                            rooms in sagar mp,
                            sagar ratna room,
                            hotel rooms in sagar,
                            oyo rooms in nagarjuna sagar,
                            rooms in nagarjuna sagar,
                            rooms for rent in sagar mp,
                            oyo rooms in anand sagar shegaon,
                            rooms for rent in sagar nagar visakhapatnam,
                            oyo rooms in sagar,
                            rooms in sagara karnataka,
                            rooms in sagara shimoga,
                            room on rent in sagarpur,
                            rooms in sagarpur,
                            oyo rooms in sagara karnataka,
                            room for rent in singapore,
                            room for rent movie,
                            room for rent in dubai,
                            room for rent toronto,
                            room for rent in brampton,
                            room for rent san diego,
                            room for rent in abu dhabi,
                            room for rent los angeles,
                            single room for rent,
                            single room for rent near me,
                            craigslist room for rent,
                            studio room for rent,
                            gumtree room for rent,
                            kijiji room for rent,
                            qatar living room for rent,
                            find a room for rent,
                            olx room for rent,
                            apartment 1 room for rent,
                            rooms for rent near me,
                            rooms for rent,
                            rooms for rent san diego,
                            rooms for rent nyc,
                            rooms for rent las vegas,
                            rooms for rent $100 a week near me,
                            rooms for rent craigslist,
                            rooms for rent london,
                            rooms for rent near me cheap,
                            rooms for rent cheap,
                            hotels in sagar for unmarried couples,
                            hotels in sagar madhya pradesh,
                            hotel in sagar m p near railway station,
                            oyo rooms,
                            hotel in sagar near highway,
                            hotel in sagar near bus stand,
                            best hotel in sagar madhya pradesh,
                            rooms in sagar mp,

                            olx house for rent in sagar mp,
                            single room for rent in sagar,
                            house for rent in makronia, sagar mp,
                            house on rent in sagar mp,
                            olx sagar property,
                            olx house for rent in gopalganj sagar mp
                            "/>
                <title>RoomsNearYou | Online Rooms Renting Website</title>
            </Helmet>
            <Header />
            <Cities />
            <Features />
            <About />
            {/* <RecentRooms /> */}
            <Pricing />

            <Footer />
        </div>
    )
}
