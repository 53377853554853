import React, { useState } from 'react'
import { Row, Col, Card, Carousel, Spinner, Modal, Button, Container } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaRegFolderOpen } from "react-icons/fa";
import { SRLWrapper } from "simple-react-lightbox";
import { QUERY_BOOKED_ROOMS, ROOM_BY_ID } from '../../Queries'
import { useQuery, useLazyQuery } from '@apollo/client'

import fire from './fireicon.svg'


export default function BookedRooms() {

    const tenantId = localStorage.getItem('TenantId');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [getRoomByIdLazyQuery, { data: roomByIdData, loading: roomByIdLoading }] = useLazyQuery(ROOM_BY_ID);

    const { data: bookedRoomData, loading: bookedRoomLoading, error: bookedRoomError } = useQuery(QUERY_BOOKED_ROOMS, {
        variables: {
            tenantId: `${tenantId}`
        }
    });
    if (bookedRoomError) {
        console.log("tenantByIdError", bookedRoomError)
    }
    if (bookedRoomData) {
        console.log("BookedData", bookedRoomData.getBookedRooms)
    }

    function fetchRoomById(rId) {
        handleShow()
        getRoomByIdLazyQuery({
            variables: {
                roomId: `${rId}`
            }
        })
    }
    console.log("ROOMBYID", roomByIdData)
    return (
        <div>
            <Card>
                <Card.Body>
                    {
                        bookedRoomLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block" variant="danger" style={{ fontSize: '13px', height: '20px', width: '20px' }}>
                            </Spinner> :
                            bookedRoomData && bookedRoomData.getBookedRooms.length === 0 ? 
                            <Container>
                            <Row>
                                <Col>
                                   <FaRegFolderOpen style={{fontSize:'100px'}} className="mx-auto d-block"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 style={{color:'#e74c3c', textAlign:'center', fontFamily:'DM Sans', marginTop:'10px', fontSize:'30px'}}>No Booked Rooms Found!!!</h5>
                                </Col>
                            </Row>
                        </Container>
                             :

                                bookedRoomData && bookedRoomData.getBookedRooms.map(bookedData => {
                                    return (
                                        <>
                                            <Row>
                                                <Col md={6}>
                                                    <p style={{ color: '#8D448B', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '18px' }}>{bookedData.roomType} <span style={{ color: '#d35400', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '12px' }}>(Request is Pending )</span></p>
                                                    <Row>
                                                        <Col>
                                                            <Carousel style={{ width: '150px', height: '150px' }}>
                                                                {
                                                                    bookedData.roomImgKey.map(bookedImages => {
                                                                        return (

                                                                            <Carousel.Item style={{ width: '150px', height: '150px' }}>
                                                                                <img
                                                                                    className="d-block w-100"
                                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${bookedImages}`}
                                                                                    alt="First slide"
                                                                                    style={{ width: '150px', height: '150px' }}
                                                                                />
                                                                            </Carousel.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </Carousel>
                                                        </Col>
                                                        <Col>
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Rent:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{bookedData.roomRent}</span></p>
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Area:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{bookedData.roomArea}</span></p>
                                                            <p><span style={{ color: '#303030', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Date:</span> <span style={{ color: '#818080', fontFamily: 'DM Sans', fontSize: '15px' }}>{bookedData.roomUploadDate}</span></p>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row>
                                                        <Col md={10}><p style={{ color: '#818080', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px' }}>
                                                            <img
                                                                src={fire}
                                                                alt="First slide"
                                                                style={{ width: '20px', height: '20px' }} /> <span style={{ color: '#303030', fontFamily: 'DM Sans', fontSize: '15px' }}>50</span> people already view this property, Hurry Up !! </p></Col>
                                                        <Col md={2}><p style={{ color: '#ff7f50', fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '15px', cursor:'pointer' }} onClick={() => fetchRoomById(bookedData.roomId)}>View</p></Col>
                                                    </Row>

                                                </Col>
                                            </Row>
                                            <hr />
                                        </>
                                    )
                                })

                    }

                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    {
                        roomByIdLoading ?
                            <Spinner animation="border" role="status" className="mx-auto d-block">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> :
                            <Container>
                                <Row>
                                    <Col>
                                        <SRLWrapper>
                                            <Carousel style={{ width: '100%', height: '200px' }}>
                                                {
                                                    roomByIdData && roomByIdData.getRoomById.imageId.map(activeImages => {
                                                        return (

                                                            <Carousel.Item style={{ width: '100%', height: '200px' }}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={`https://softbuc.s3.us-east-2.amazonaws.com/${activeImages}`}
                                                                    alt="First slide"
                                                                    style={{ width: '100%', height: '200px' }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </SRLWrapper>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', color: 'rgb(141, 68, 139)' }}>{roomByIdData && roomByIdData.getRoomById.roomType}</p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Rent: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.price}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Area: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.area}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Date: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadDate}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Upload Time: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.uploadTime}</span></p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Capacity: <span style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px', color: 'rgb(129, 128, 128)' }}>{roomByIdData && roomByIdData.getRoomById.capacity} Peoples</span></p>
                                    </Col>
                                    <Col style={{ borderLeft: '1px solid rgb(231, 76, 60)' }}>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Bathroom Attatched
                                            {
                                                roomByIdData && roomByIdData.getRoomById.bathroomAttach === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Kitchen Available
                                            {
                                                roomByIdData && roomByIdData.getRoomById.kitchenAvailable === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Water Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.waterBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                        <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Electricity Price Included
                                            {
                                                roomByIdData && roomByIdData.getRoomById.electricBillInclude === 'yes' ?
                                                    <FaCheckCircle style={{ color: 'green' }} /> :
                                                    <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    roomByIdData && roomByIdData.getRoomById.remark === "" ? '' :
                                        <Row>
                                            <Col style={{ background: '#bdc3c7', borderRadius: '10px', marginTop: '5px' }}>
                                                <p style={{ fontFamily: 'DM Sans', fontWeight: 'bold', fontSize: '13px' }}>Remark: <span style={{ fontFamily: 'DM Sans', fontSize: '13px', color: '#000' }}>
                                                    {roomByIdData && roomByIdData.getRoomById.remark}
                                                </span></p>
                                            </Col>
                                        </Row>
                                }

                            </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="modalCloseBtn">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
