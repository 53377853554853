import React, { useState, useRef } from 'react'
import { Container, Row, Col, Card, Tab, Button, Alert, Form } from 'react-bootstrap'
import './TenantCss/TenantDetailsCss.css'
import { ACTIVATE_TENANT } from '../../Queries'
import { useMutation } from '@apollo/client';

export default function TenantDetails() {
    const tenantId = localStorage.getItem('TenantId');
    const [validated, setValidated] = useState(false);
    const [activeTenantMutation] = useMutation(ACTIVATE_TENANT);

    let firstName = useRef(null)
    let lastName = useRef(null)
    let email = useRef(null)
    let gender = useRef(null)
    let occupation = useRef(null)
    let city = useRef(null)
    let state = useRef(null)
    let pincode = useRef(null)
    let identityType = useRef(null)
    let identityNo = useRef(null)


    function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const FirstName = firstName.current.value;
            const LastName = lastName.current.value;
            const Email = email.current.value;
            const Gender = gender.current.value;
            const Occupation = occupation.current.value;
            const City = city.current.value;
            const State = state.current.value;
            const Pincode = pincode.current.value;
            const IdentityType = identityType.current.value;
            const IdentityNo = identityNo.current.value;

            activeTenantMutation({
                variables: {
                    "tenantId": `${tenantId}`,
                    "firstName": `${FirstName}`,
                    "lastName": `${LastName}`,
                    "email": `${Email}`,
                    "gender": `${Gender}`,
                    "occupation": `${Occupation}`,
                    "city": `${City}`,
                    "state": `${State}`,
                    "pincode":`${Pincode}`,
                    "identityType": `${IdentityType}`,
                    "identityNo": `${IdentityNo}`
                }
            })
        }

        setValidated(true);
    }


    return (
        <div>
            <Container className="landlordDetailsContainer">
                <Tab.Container defaultActiveKey="uploadRooms">
                    <Row>
                        <Col md={12}>
                            <Card className="tenantDetailsCard">
                                <Card.Body>
                                    <Alert variant='primary' style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                        <span>Please enter your <span style={{ color: '#e74c3c' }}>details</span> first!!!</span>
                                    </Alert>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">First name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    className="tenantDetailsInput"
                                                    ref={firstName}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter First Name</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                <Form.Label className="tenantDetailsLabel">Last name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    className="tenantDetailsInput"
                                                    ref={lastName}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter Last Name</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">Email</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="email"
                                                    placeholder="Enter Email Address"
                                                    className="tenantDetailsInput"
                                                    ref={email}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter Email Address</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">Gender</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="select"
                                                    className="tenantDetailsInput"
                                                    ref={gender}
                                                >
                                                    <option value="" selected="selected" disabled="disabled">Choose Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Choose Gender</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">Occupation</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="select"
                                                    className="tenantDetailsInput"
                                                    ref={occupation}
                                                >
                                                    <option value="" selected="selected" disabled="disabled">Choose Occupation</option>
                                                    <option value="Student">Student</option>
                                                    <option value="Employee (Private Sector)">Employee (Private Sector)</option>
                                                    <option value="Employee (Government Sector)">Employee (Government Sector)</option>
                                                    <option value="Business">Business</option>
                                                    <option value="Other">Other</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Choose Occupation</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">City</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter City"
                                                    className="tenantDetailsInput"
                                                    ref={city}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter City</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">State</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="select"
                                                    className="tenantDetailsInput"
                                                    ref={state}
                                                >
                                                    <option value="" selected="selected" disabled="disabled">Choose State</option>
                                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                    <option value="Assam">Assam</option>
                                                    <option value="Bihar">Bihar</option>
                                                    <option value="Chandigarh">Chandigarh</option>
                                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                                    <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                    <option value="Daman and Diu">Daman and Diu</option>
                                                    <option value="Delhi">Delhi</option>
                                                    <option value="Lakshadweep">Lakshadweep</option>
                                                    <option value="Puducherry">Puducherry</option>
                                                    <option value="Goa">Goa</option>
                                                    <option value="Gujarat">Gujarat</option>
                                                    <option value="Haryana">Haryana</option>
                                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                    <option value="Jharkhand">Jharkhand</option>
                                                    <option value="Karnataka">Karnataka</option>
                                                    <option value="Kerala">Kerala</option>
                                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                    <option value="Maharashtra">Maharashtra</option>
                                                    <option value="Manipur">Manipur</option>
                                                    <option value="Meghalaya">Meghalaya</option>
                                                    <option value="Mizoram">Mizoram</option>
                                                    <option value="Nagaland">Nagaland</option>
                                                    <option value="Odisha">Odisha</option>
                                                    <option value="Punjab">Punjab</option>
                                                    <option value="Rajasthan">Rajasthan</option>
                                                    <option value="Sikkim">Sikkim</option>
                                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                                    <option value="Telangana">Telangana</option>
                                                    <option value="Tripura">Tripura</option>
                                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                    <option value="Uttarakhand">Uttarakhand</option>
                                                    <option value="West Bengal">West Bengal</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Choose State</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">Pincode</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Pincode"
                                                    className="tenantDetailsInput"
                                                    ref={pincode}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter Pincode</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">ID Type</Form.Label>
                                                <Form.Control
                                                    required
                                                    as="select"
                                                    className="tenantDetailsInput"
                                                    ref={identityType}
                                                >
                                                    <option value="" selected="selected" disabled="disabled">Choose ID Type</option>
                                                    <option value="Aadhar Card">Aadhar Card</option>
                                                    <option value="Voter Id">Voter Id</option>
                                                    <option value="PAN Card">PAN Card</option>
                                                </Form.Control>
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Choose Occupation</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="tenantDetailsLabel">ID Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter ID Number"
                                                    className="tenantDetailsInput"
                                                    ref={identityNo}
                                                />
                                                <Form.Control.Feedback className="tenantDetailsLookGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="tenantDetailsInvalid">Enter ID Number</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Button type="submit" className="mx-auto d-block tenantDetailsBtn">Submit</Button>
                                    </Form>
                                </Card.Body>
                            </Card>

                        </Col>

                    </Row>
                </Tab.Container>
            </Container>
        </div>
    )
}
