import React, { useState, useRef } from 'react'
import { Tab, Row, Col, Container, Card, Alert, Form, Button } from 'react-bootstrap';
import './LandlordCss/LandlordDetails.css'
import { useMutation } from '@apollo/client';
import { ACTIVATE_LANDLORD } from '../../Queries'

export default function LandlordDetails() {
    const landlordId = localStorage.getItem('LandlardId');
    const [activeLandlordMutation] = useMutation(ACTIVATE_LANDLORD);

    const [validated, setValidated] = useState(false);

    let firstName = useRef(null)
    let lastName = useRef(null)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const FirstName = firstName.current.value;
            const LastName = lastName.current.value;

            activeLandlordMutation({
                variables: {
                    activeLandlordLandlordId: `${landlordId}`,
                    activeLandlordFirstName: `${FirstName}`,
                    activeLandlordLastName: `${LastName}`
                }
            })
        }

        setValidated(true);
    };

    return (
        <>
            <Container className="landlordDetailsContainer">
                <Tab.Container defaultActiveKey="uploadRooms">
                    <Row>
                        <Col md={12}>
                            <Card className="landlordDetailsCard">
                                <Card.Body>
                                    <Alert variant='primary' style={{ textAlign: 'center', fontFamily: 'DM Sans', fontWeight: 'bold' }}>
                                        <span>Please enter your <span style={{ color: '#e74c3c' }}>full name</span> before uploading the rooms!!!</span>
                                    </Alert>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Form.Label className="landlordDetailsLabel">First name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    className="landlordDetailsInput"
                                                    ref={firstName}
                                                />
                                                <Form.Control.Feedback className="landlordDetailsLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="landlordDetailsInvalid">Enter First Name</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                <Form.Label className="landlordDetailsLabel">Last name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    className="landlordDetailsInput"
                                                    ref={lastName}
                                                />
                                                <Form.Control.Feedback className="landlordDetailsLooksGood">Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid" className="landlordDetailsInvalid">Enter Last Name</Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Button type="submit" className="mx-auto d-block landlordDetailsBtn">Submit</Button>
                                    </Form>
                                </Card.Body>
                            </Card>

                        </Col>

                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}
